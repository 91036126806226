import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Container,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Chip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Background image for the slider
import backgroundImage from '../assets/services.jpg';

const Hero = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({ name: '', phone: '254', email: '' });
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);
  const [fadeClass, setFadeClass] = useState('fadeIn');

  // Fetch questions from the API
  useEffect(() => {
    setLoadingQuestions(true);
    axios.get('https://api.cope.ke/questions')
      .then((response) => {
        setQuestions(response.data);
        setLoadingQuestions(false);
      })
      .catch((error) => {
        setError('Error fetching questions. Please Refresh Page');
        setLoadingQuestions(false);
        setSnackbarOpen(true);
        console.error(error);
      });
  }, []);

    // Update slide and apply fade effect
    useEffect(() => {
      const interval = setInterval(() => {
        setFadeClass('fadeOut'); // Start fade-out
        setTimeout(() => {
          setActiveSlide((prev) => (prev + 1) % slides.length); // Switch to the next slide
          setFadeClass('fadeIn'); // Start fade-in
        }, 1000); // Wait for fade-out to complete before switching
      }, 8000); // Total interval time per slide
  
      return () => clearInterval(interval);
    }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenTermsModal = () => setOpenTermsModal(true);
  const handleCloseTermsModal = () => setOpenTermsModal(false);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

   // Handle phone number change
   const handlePhoneChange = (e) => {
    const input = e.target.value;

    // Ensure phone number starts with "254" and limit the total length to 12 digits
    if (!input.startsWith('254')) {
      setFormValues({ ...formValues, phone: '254' });
    } else if (input.length <= 12 && /^[0-9]*$/.test(input)) {
      setFormValues({ ...formValues, phone: input });
    }
  };

  const handleChipClick = (question_id, option) => {
    setFormValues({
      ...formValues,
      [`question_${question_id}`]: option,
    });
    if (activeStep === questions.length - 1) {
      setActiveStep(questions.length);
    } else {
      handleNext();
    }
  };


  const handleSubmit = async () => {
    if (!formValues.name || !formValues.phone || !formValues.email || !acceptTerms) {
      setError('Please fill out all mandatory fields (Name, Phone, Email, and accept terms).');
      setSnackbarOpen(true);
      return;
    }
  
    setLoadingSubmit(true);
  
    let userId = null;
  
    try {
      // First, post the user data to the /users endpoint
      const userResponse = await axios.post('https://api.cope.ke/users', {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
      });
  
      // Log the full response for debugging
      console.log('User Response:', userResponse.data);
  
      // Extract user_id from the response (either new or existing)
      userId = userResponse.data.user_id;
  
      if (!userId) {
        throw new Error('Missing user_id in response data');
      }
  
  
      // Now prepare and post the responses to the /responses endpoint
      const responses = Object.keys(formValues)
        .filter(key => key.startsWith("question_"))
        .map(key => {
          const questionId = key.split('_')[1];
          return {
            user_id: userId,
            question_id: parseInt(questionId),
            answer: formValues[key],
          };
        });
  
 
      // Post the responses
      await axios.post('https://api.cope.ke/responses', { responses });
  
      // Success handling
      setSnackbarMessage('Submission Successful!');
      handleCloseModal();
      setSnackbarOpen(true);
      setLoadingSubmit(false);
    } catch (error) {
      console.error('Error submitting data:', error.response?.data?.error || error.message);
  
      // Handle missing fields or general errors
      const errorMessage = error.response?.data?.error || error.message;
      setError('Error submitting data: ' + errorMessage);
      setSnackbarMessage('Error submitting data: ' + errorMessage);
      setSnackbarOpen(true);
      setLoadingSubmit(false);
    }
  };
  
  

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const renderQuestions = () => {
    if (loadingQuestions) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <CircularProgress />
        </Box>
      );
    }
  
    if (questions.length === 0) {
      return <Typography>No questions available.</Typography>;
    }
  
    const currentQuestion = questions[activeStep];
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom sx={{ color: theme.palette.primary.main }}>
            {currentQuestion.question_text}
          </Typography>
          <Box>
            {/* Split the options string by commas */}
            {currentQuestion.options.split(',').map((option, idx) => (
              <Chip
                key={idx}
                label={option.trim()}  
                clickable
                sx={{
                  backgroundColor: formValues[`question_${currentQuestion.question_id}`] === option ? '#00695C' : '#F9A64F',
                  color: formValues[`question_${currentQuestion.question_id}`] === option ? 'white' : 'black',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
                onClick={() => handleChipClick(currentQuestion.question_id, option)}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    );
  };
  

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
  };

  const styles = {
    fadeIn: {
      animation: 'fadeIn 1s ease-in forwards',
      opacity: 0,
    },
    fadeOut: {
      animation: 'fadeOut 1s ease-out forwards',
      opacity: 1,
    },
    '@keyframes fadeIn': {
      '0%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
    '@keyframes fadeOut': {
      '0%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
  };

  const slides = [
    "Your Mental Wellness, Simplified",
    "Professional Care at Your Fingertips",
    "Easy, Flexible, Accessible, Anywhere",
    "Great Customer Service",
    "Self-Care Starts Here"
  ];

  return (
    <>
      {/* Static Background Image */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '85vh',
          paddingTop:'30px',
          textAlign: 'center',
          color: '#00695C',
          fontWeight: 'bold',
          fontSize: '2rem',
          marginBottom:'20px',
          ...styles // Add keyframes to global styles
        }}
      >
        {/* Slide Text with Fade Effect */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 2,
            transition: 'opacity 1s ease-in-out',
            ...(fadeClass === 'fadeIn' ? styles.fadeIn : styles.fadeOut),
          }}
        >
          {slides[activeSlide]}
        </Typography>

        {/* Get Started Button - Below Slider */}
        <Box sx={{ position: 'relative', top:'50%',  textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#00695C', padding: '10px 20px', color: '#fff' }}
            onClick={handleOpenModal}
          >
            Get Started
          </Button>
        </Box>
      </Box>

      {/* Modal for displaying questions */}
      {/* Modal for displaying questions */}
<Modal open={openModal} onClose={handleCloseModal}>
  <Box
    sx={{
      width: { xs: '90%', sm: '80%', md: '50%' }, // Responsive width
      maxWidth: '600px', // Maximum width for larger screens
      backgroundColor: '#fff',
      padding: { xs: '16px', sm: '20px' }, // Responsive padding
      borderRadius: '8px',
      margin: 'auto',
      marginTop: { xs: '20px', sm: '50px' },
      maxHeight: '85vh',
      overflowY: 'auto',
      position: 'relative',
    }}
  >
    <Typography variant="h5" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
      Assess Your Mental Wellness
    </Typography>

    {/* Stepper for questions */}
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<Box sx={{ height: '4px', backgroundColor: '#F9A64F' }} />}
      sx={{
        '& .MuiStepConnector-line': {
          borderColor: '#F9A64F',
        },
      }}
    >
      {questions.map((_, index) => (
        <Step key={index}>
          <StepLabel
            StepIconProps={{
              sx: {
                color: activeStep === index ? theme.palette.primary.main : theme.palette.secondary.main,
              },
            }}
          />
        </Step>
      ))}
    </Stepper>

    {/* Render current question */}
    <form>
      {activeStep < questions.length ? (
        renderQuestions()
      ) : (
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1">Please provide your contact details:</Typography>
          <TextField
            required
            id="name"
            label="Name"
            name="name"
            onChange={handleChange}
            value={formValues.name || ''}
            sx={{ mb: 2, width: '100%' }}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <TextField
            required
            id="phone"
            label="Phone"
            name="phone"
            onChange={handlePhoneChange}
            value={formValues.phone || ''}
            sx={{ mb: 2, width: '100%' }}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
            inputProps={{
              maxLength: 12,
            }}
          />
          <TextField
            required
            id="email"
            label="Email"
            name="email"
            type="email"
            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
            value={formValues.email || ''}
            sx={{ mb: 2, width: '100%' }}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
            error={!!error && error.includes('email')}
            helperText={error.includes('email') && error}
          />

          {/* Checkbox for accepting terms */}
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                sx={{ color: '#2C1C18', '&.Mui-checked': { color: '#00695C' } }}
              />
            }
            label={
              <>
                I accept the{' '}
                <Typography
                  component="span"
                  sx={{ color: '#00695C', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={handleOpenTermsModal}
                >
                  terms and conditions
                </Typography>
              </>
            }
          />

          {/* Submit Button with Circular Spinner */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ backgroundColor: '#00695C' }}
              disabled={!acceptTerms || loadingSubmit}
            >
              Submit
            </Button>
            {loadingSubmit && <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%' }} />}
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={2}>
        {activeStep > 0 && (
          <Button
            variant="contained"
            onClick={handleBack}
            sx={{
              backgroundColor: '#2C1C18',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#F9A64F', color: '#000' },
              padding: '10px',
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        )}
      </Box>
    </form>
  </Box>
</Modal>

      {/* Terms and Conditions Modal */}
      <Modal open={openTermsModal} onClose={handleCloseTermsModal}>
  <Container
    maxWidth="sm"
    sx={{
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      marginTop: '50px',
      maxHeight: '80vh',
      overflowY: 'auto',
    }}
  >
    <Typography variant="h6" sx={{fontWeight:'bold'}}>Terms and Conditions for Using Cope.ke</Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>
      Effective Date: Sept 2024
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Welcome to Cope.ke, a platform offering online therapy services for individuals and organizations. These Terms and Conditions ("Terms") govern your use of our website, mobile app, and services (collectively referred to as the "Platform"). By accessing or using Cope.ke, you agree to these Terms. Please read them carefully before using our services. If you do not agree with these Terms, you should not use our Platform.
    </Typography>

    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>1. Introduction and Acceptance of Terms<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke provides access to professional mental health services, including therapy sessions, self-assessment tools, and related mental health content. By using the Platform, you acknowledge that:
      <ul>
        <li>You are at least 18 years old or have obtained parental or guardian consent to use the Platform.</li>
        <li>You are legally able to enter into a contract.</li>
        <li>You accept these Terms and agree to abide by them.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>2. Privacy Policy<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Your privacy is essential to us. Our Privacy Policy outlines how we collect, store, and protect your data. By using Cope.ke, you agree to the terms of our Privacy Policy. We use industry-standard security measures to protect your data, including encryption and secure communications.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>3. Eligibility for Services<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke is designed for users seeking mental health services in the form of therapy sessions, consultations, or self-assessment tools. To use our Platform, you must:
      <ul>
        <li>Be a resident of a country where our services are legally available.</li>
        <li>Provide accurate and up-to-date personal and contact information.</li>
      </ul>
      Our services are not intended for users in immediate crisis or medical emergencies. If you are experiencing a medical or mental health emergency, please contact local emergency services.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>4. Use of the Platform<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      You agree to use Cope.ke only for lawful purposes and in accordance with these Terms. You may not:
      <ul>
        <li>Use the Platform for any fraudulent or illegal activities.</li>
        <li>Violate any applicable local, national, or international law while using the Platform.</li>
        <li>Interfere with the security or functionality of the Platform, including hacking, introducing viruses, or data scraping.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>5. Therapist Services and Responsibilities<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke connects users with licensed and qualified mental health professionals ("Therapists"). All Therapists on the Platform are independent practitioners and not employees of Cope.ke. The responsibility for the quality of therapy services provided rests solely with the Therapist. Cope.ke:
      <ul>
        <li>Does not provide medical advice, diagnosis, or treatment.</li>
        <li>Is not liable for any professional advice or actions taken by a Therapist during your sessions.</li>
      </ul>
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>6. Booking and Payment Terms<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      a. Booking Appointments:<br />
      Users can book therapy sessions through the Platform by selecting a Therapist, choosing an available time slot, and confirming their booking. Booking confirmations will be sent via email, SMS, or other communication channels.
      <br />
      b. Payments:<br />
      Payment is required at the time of booking unless otherwise stated. All payments are processed securely, and prices will be displayed in the local currency or a specified alternative. Refunds may be issued under limited circumstances and at the sole discretion of Cope.ke.
      <br />
      c. Cancellations and Rescheduling:<br />
      You may cancel or reschedule your appointment at least 24 hours in advance without penalty. Late cancellations or no-shows may result in a fee or loss of the session fee.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>7. Self-Assessment Tools<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke offers self-assessment tools designed to help users better understand their mental health status. These tools are intended for informational purposes only and are not a substitute for professional mental health evaluation or diagnosis. They should not be relied upon to make critical health decisions.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>8. Data Privacy and Security<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Your personal information and data privacy are critical to us. Cope.ke complies with applicable data protection laws. By using our Platform, you agree to our collection, use, and disclosure of your information as described in our Privacy Policy.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>9. Platform Availability and Changes<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      We strive to ensure that our Platform is available 24/7, but we cannot guarantee uninterrupted access. Cope.ke reserves the right to modify, update, or discontinue any part of the Platform at any time without prior notice.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>10. Third-Party Links and Content<br /></Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Our Platform may contain links to third-party websites, resources, or services. These links are provided for your convenience, and we do not endorse or take responsibility for the content or practices of these third parties.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>11. Disclaimer of Warranties<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke makes no warranties or guarantees about the accuracy, reliability, or availability of the Platform or the services provided by Therapists. The Platform is provided on an "as-is" and "as-available" basis without any express or implied warranties of any kind.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>12. Limitation of Liability<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      To the maximum extent permitted by law, Cope.ke and its affiliates, officers, directors, and employees are not liable for any indirect, incidental, or consequential damages arising from the use of the Platform or therapy services.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>13. Indemnification<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      You agree to indemnify and hold harmless Cope.ke and its affiliates from any claims, liabilities, damages, losses, or expenses arising out of your use of the Platform or your violation of these Terms.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>14. Governing Law and Dispute Resolution<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      These Terms are governed by the laws of [Insert Country]. Any disputes arising out of or relating to these Terms or your use of Cope.ke shall be resolved through good faith negotiations between the parties, and if necessary, binding arbitration.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>15. Changes to Terms<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      We may modify these Terms from time to time. Any changes will be posted on our website, and you will be notified through email or other communication channels.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>16. Termination of Services<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      Cope.ke reserves the right to terminate or suspend your account or access to the Platform for any reason, including breach of these Terms.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, fontWeight:'bold' }}>17. Contact Information<br />
    </Typography>
    <Typography variant="body1" sx={{ mt: 2 }}>
      If you have any questions or concerns regarding these Terms or your use of the Platform, please contact us at:
      <br />
      Email: hello@cope.ke<br />
    </Typography>

    <Button onClick={handleCloseTermsModal} sx={{ mt: 2 }}>
      OK
    </Button>
  </Container>
</Modal>


      {/* Snackbar for submission feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Submission Successful!
        </Alert>
      </Snackbar>

      {/* Snackbar for error feedback */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Hero;
