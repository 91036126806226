import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import {
  Paper,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  Grid,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";


const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "About Us",
    content: "We provide access to the best healthcare services in Kenya.",
    subContent:
      "You're not just choosing a medical provider; you're joining a movement",
    image:
      "https://images.unsplash.com/photo-1600091474842-83bb9c05a723?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    action: "Back",
    actionLink: "/",
    cardLink: "/",
  },
  {
    title: "Mission",
    content: "To give access to the best medical services in Kenya.",
    subContent:
      "We're breaking down the barriers that have kept too many people from receiving quality healthcare",
    image: "https://source.unsplash.com/random?healthcare",
  },
  {
    title: "Vision",
    content: "To be at the forefront of the global healthcare revolution,",
    subContent:
      "Quality healthcare services should not be a privilege but a universal right.",
    image: "https://source.unsplash.com/random?technology",
  },
  // Add more card data as needed
];
const navbarHeight = 64;

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: `${navbarHeight}px`,
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
  position: "relative", // Needed for absolute positioning of the overlay
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const textStyle = {
  zIndex: 2, // Ensures text is above the overlay
  color: "white",
  fontSize: "48px",
  position: "relative",
};

const subtitle = {
  zIndex: 2, // Ensures text is above the overlay
  color: "white",
  fontSize: "28px",
  position: "relative",
};

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);


  const handleSignInWithEmail = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await axios.get(
        "https://api.cope.ke/api/accounts"
      );

      const accounts = response.data;

      // Create a regex and find the matching account
      const regex = new RegExp(email);
      const matchingAccount = accounts.find(
        (account) => account.email === email
      );
      console.log(matchingAccount);
      // Navigate based on user category
      if (matchingAccount) {
        if (matchingAccount.is_admin) {
          navigate("/macrobian", { state: { res_admin: matchingAccount } });
        } else if (matchingAccount.is_subscriber) {
          navigate("/home", {
            state: { res_serviceProvider: matchingAccount },
          });
        } else if (matchingAccount.is_active) {
          navigate("/user-profile", {
            state: { res_patient: matchingAccount },
          });
        }
      } else {
        alert("No matching account found or account is inactive.");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      alert(`An error occurred while signing in: ${error.message}`);
    }
  };

  return (
    <div style={{ maxHeight: "100vh" }}>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          About Us
        </Typography>
        <Typography variant="h5" style={subtitle}>
          A sanctuary for the soul, a workshop for the mind, and a training
          ground for the body
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#4caf50",
          }}
        >
          Get Started
        </Button>
      </div>
      <Paper
        style={{
          padding: 20,
          backgroundColor: "#e1f5fe",
          color: "#FFF",
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} justifyContent="space-between">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card sx={{ backgroundColor: "#b2dfdb" }}>
                    <CardMedia
                      sx={{ height: 100 }}
                      image={card.image}
                      title={card.title}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#4caf50",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {card.content}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {card.subContent}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ color: "#8d6e63" }}
                        size="small"
                        href={card.actionLink}
                      >
                        {card.action}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              style={{ marginTop: 20, textAlign: "center", color: "#3f51b5" }}
            >
              What Our Team Members Say
            </Typography>
            <Slider {...settings} style={{ margin: "20px" }}>
              {testimonials.map((testimonial, index) => (
                <div key={index} style={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontStyle: "italic",
                      textAlign: "center",
                      color: "#7986cb",
                    }}
                  >
                    "{testimonial.quote}"
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      marginTop: "10px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    - {testimonial.author}, {testimonial.position} at{" "}
                    {testimonial.company}
                  </Typography>
                </div>
              ))}
            </Slider>
            <Stack>
              <Typography
                color="#7986cb"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                How are you feeling today?
              </Typography>
              <Button
                variant="contained"
                style={{
                  alignSelf: "center",
                  backgroundColor: "#4caf50",
                }}
              >
                Find Out
              </Button>
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <SignIn
                  handleSignInWithEmail={handleSignInWithEmail}
                />
              </Modal>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#7986cb" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#7986cb" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
          <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Terms of Use
              </Typography>
            </div>            
          </Grid>

          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default AboutUs;
