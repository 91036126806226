import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import axios from 'axios';

import { UserContext } from '../UserContext';


const ServiceProviderClients = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useContext(UserContext);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    provider_id: user.provider_id // Set provider_id from context
  });

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      console.log(user.provider_id)
      const response = await axios.get(`https://api.cope.ke/sp-clients/${user.provider_id}`);

      setClients(response.data);
      setFilteredClients(response.data);
      setLoading(false);
    } catch (error) {
      showSnackbar('No clients found', 'error');
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredClients(
      clients.filter(
        (client) =>
          client.name.toLowerCase().includes(value) ||
          client.email.toLowerCase().includes(value) ||
          client.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (client) => {
    setSelectedClient(client);
    setFormData(client);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedClient(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      gender: ''
    });
  };

  const handleOpenAddEditModal = (client = null) => {
    setSelectedClient(client);
    setFormData(client || {
      name: '',
      email: '',
      phone: '',
      gender: '',
      provider_id: user.provider_id // Ensure provider_id is included in form data
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedClient(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      gender: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      if (selectedClient) {
        await axios.put(`https://api.cope.ke/sp-clients/${selectedClient.id}`, formData);
      } else {
        await axios.post('https://api.cope.ke/sp-clients', formData);
      }
      setSaving(false);
      showSnackbar('Client saved successfully', 'success');
      fetchClients();
      handleCloseAddEditModal();
    } catch (error) {
      setSaving(false);
      const errorMessage = error.response?.data?.error || 'Failed to save client';
      showSnackbar(errorMessage, 'error');
    }
  };
  

  const handleDelete = async (clientId) => {
    try {
      await axios.delete(`https://api.cope.ke/sp-clients/${clientId}`);
      fetchClients();
    } catch (error) {
      showSnackbar('Failed to delete client', 'error');
    }
  };

  const showSnackbar = (message, severity) => setSnackbar({ open: true, message, severity });

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredClients.length > 0 ? (
            filteredClients.map((client) => (
              <TableRow key={client.id} onClick={() => handleOpenDetailsModal(client)}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.email}</TableCell>
                <TableCell>{client.phone}</TableCell>
                <TableCell>{client.gender}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenAddEditModal(client)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(client.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} sx={{color:'red'}} align="center">
                No clients available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>

      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <Typography variant="h6">Client Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          placeholder="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          placeholder="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          type="tel"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="gender"
          placeholder="Gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>Your Clients</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search clients"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddEditModal()}
          sx={{ ml: 2 }}
        >
          Add
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable()
      )}

      {/* Add/Edit Client Modal */}
      <Modal open={openAddEditModal} onClose={handleCloseAddEditModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6">{selectedClient ? 'Edit Client' : 'Add Client'}</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            placeholder="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            type="tel"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            placeholder="Gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={saving ? <CircularProgress size={20} /> : null}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar for messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderClients;
