import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  IconButton,
  Snackbar,
  Alert,
  Divider,
  CircularProgress,
  TextField,
  Tooltip,
  Breadcrumbs,
  Link as MuiLink,
  Container,
  Chip,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  Facebook,
  Twitter,
  LinkedIn,
  WhatsApp,
} from "@mui/icons-material";
import axios from "axios";
import ScrollTopFab from "../components/ScrollTopFab";
import Navbar from '../components/Navbar'
import { useNavigate, useLocation } from "react-router-dom";

const Community = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [searchTerm, setSearchTerm] = useState("");
  const [postsToShow, setPostsToShow] = useState(10);

  const [activePage, setPage] = useState('Our Blog');

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.slice(1) || 'Our Blog';
    setPage(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    window.scrollTo(0, 0); // Scroll to top
    document.title = "Our Blog | Cope Wellness"; // Update page title
  }, [location]);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = () => {
    setLoading(true);
    axios
      .get("https://api.cope.ke/blogposts")
      .then((response) => {
        const sortedPosts = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setBlogPosts(sortedPosts);
        setFilteredPosts(sortedPosts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog posts", error);
        setSnackbar({ open: true, message: "Failed to fetch blog posts", severity: "error" });
        setLoading(false);
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const searchQuery = event.target.value.toLowerCase();
    const searchResults = blogPosts.filter((post) =>
      post.title.toLowerCase().includes(searchQuery)
    );
    setFilteredPosts(searchResults);
  };

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const handleShare = (platform, title, content) => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(`${title} - ${content}`);
    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      twitter: `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
      linkedin: `https://www.linkedin.com/shareArticle?url=${url}&title=${text}`,
      whatsapp: `https://wa.me/?text=${text}%20${url}`,
    };
    window.open(shareUrls[platform], "_blank");
  };

  const getFirstParagraph = (content) => {
    const paragraphs = content.split(/\n+/).filter((p) => p.trim() !== "");
    return paragraphs[0].length > 200 ? `${paragraphs[0].substring(0, 200)}...` : paragraphs[0];
  };

  const loadMorePosts = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setPostsToShow(postsToShow + 10);
      setLoadingMore(false);
    }, 1000);
  };

  const headerRegex = /^(?:A\s|[A-Z][a-z]+(?: [‘’'A-Za-z][a-z]*)*)[\?\!\.\:]?$/;

  const renderContentWithStyles = (content) => {
    const paragraphs = content.split("\n").filter((p) => p.trim() !== "");

    return paragraphs.map((paragraph, index) => {
      const headerWithNumbersRegex = /^\d+\.\s/;
      const romanNumeralRegex = /^[IVXLCDM]+\.\s/;

      if (headerWithNumbersRegex.test(paragraph)) {
        return (
          <Typography key={index} variant="body1" sx={{  color: "#666", fontWeight: "bold", mb: "20px" }}>
            {paragraph}
          </Typography>
        );
      }
      else if (headerRegex.test(paragraph.replace(/['‘’]/g, '')) && paragraph.length <= 50) {
        return (
          <Typography key={index} variant="h6" sx={{ color: "teal", fontWeight: "bold", mb: "20px" }}>
            {paragraph}
          </Typography>
        );
      }
      else if (!romanNumeralRegex.test(paragraph)) {
        return (
          <Typography key={index} variant="body1" color="#666" sx={{ mb: "20px" }}>
            {paragraph}
          </Typography>
        );
      }
      return null;
    });
  };

  const handleSelectBlog = (post) => {
    setSelectedBlog(post);
    window.scrollTo(0, 0); // Scroll to top
  };

  // Helper function to format the date with an ordinal suffix
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.toLocaleDateString("en-GB", { weekday: "short" });
  const dayOfMonth = date.getDate();
  const month = date.toLocaleDateString("en-GB", { month: "short" });
  const year = date.getFullYear();

  // Determine the correct ordinal suffix
  const suffix = (dayOfMonth) => {
    if (dayOfMonth > 3 && dayOfMonth < 21) return "th";
    switch (dayOfMonth % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return `${day}, ${dayOfMonth}${suffix(dayOfMonth)} ${month} ${year}`;
};



  return (
    <div>
    <Navbar />
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", height: "200px", bgcolor: "primary.main", color: "white", mb: 4 }}>
        <Typography variant="h3">Our Blog</Typography>
        <Breadcrumbs sx={{ position: "absolute", bottom: 20, left: 20, color: "white" }}>
          <MuiLink color="#f5f5f5" onClick={() => navigate('/')} sx={{cursor:'pointer'}}>Home</MuiLink>
          <Typography color="#fff">Our Blog</Typography>
        </Breadcrumbs>
      </Box>

      {!selectedBlog && (
        <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Search blog posts..."
            value={searchTerm}
            onChange={handleSearch}
            fullWidth={isMobile}
            sx={{ width: isMobile ? "100%" : "60%", maxWidth: 500 }}
          />
        </Box>
      )}

      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
                <CircularProgress color="primary" />
              </Box>
            ) : !selectedBlog ? (
              <>
                {filteredPosts.length > 0 ? (
                  filteredPosts.slice(0, postsToShow).map((post, index) => (
                    <Card key={index} sx={{ marginBottom: theme.spacing(2), boxShadow: 2 }}>
                      <CardContent>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                      <PersonIcon color="primary" sx={{ mr: 1 }} />
                          <Chip label={`Author: ${post.author || "Unknown Author"}`} sx={{ backgroundColor: "#F9A64F", color: "#fff" }} />                       </Box>
                        <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
                          {post.title}
                        </Typography>
                        <Typography variant="body1" color="#666">
                          {getFirstParagraph(post.content)}
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                          <Chip label={`Category: ${post.category}`} sx={{ backgroundColor: "#76B687", color: "#fff" }} />
                          <Chip label={`Published on: ${formatDate(post.created_at)}`} sx={{ backgroundColor: "#76B687", color: "#fff" }} />
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                          <Button variant="outlined" onClick={() => handleSelectBlog(post)} color="primary">
                            Read More
                          </Button>
                          <Box>
                            <Tooltip title="Share on Facebook">
                              <IconButton onClick={() => handleShare("facebook", post.title, post.content)} sx={{ color: "#3b5998" }}>
                                <Facebook />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Share on Twitter">
                              <IconButton onClick={() => handleShare("twitter", post.title, post.content)} sx={{ color: "#1DA1F2" }}>
                                <Twitter />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Share on LinkedIn">
                              <IconButton onClick={() => handleShare("linkedin", post.title, post.content)} sx={{ color: "#0077b5" }}>
                                <LinkedIn />
                              </IconButton>
                            </Tooltip>
                            {isMobile && (
                              <Tooltip title="Share on WhatsApp">
                                <IconButton onClick={() => handleShare("whatsapp", post.title, post.content)} sx={{ color: "#25D366" }}>
                                  <WhatsApp />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                      <ScrollTopFab />
                    </Card>
                  ))
                ) : (
                  <Typography color="red" textAlign="center" mt={4}>
                    No results found
                  </Typography>
                )}
                {filteredPosts.length > postsToShow && (
                  <Box display="flex" justifyContent="center" mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={loadMorePosts}
                      disabled={loadingMore}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {loadingMore ? <CircularProgress size={24} color="inherit" /> : "Load More"}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Card sx={{ boxShadow: 2, mb: "20px" }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <IconButton onClick={() => setSelectedBlog(null)} sx={{ color: "teal" }}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" color="primary" fontWeight="bold" gutterBottom>
                      {selectedBlog.title}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                    <Chip label={`Author: ${selectedBlog.author || "Unknown Author"}`} sx={{ backgroundColor: "#F9A64F", color: "#fff" }} />
                  </Box>
                  <Box sx={{ mb: "20px", textAlign:'justify' }}>
                    {renderContentWithStyles(selectedBlog.content)}
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                    <Chip label={`Category: ${selectedBlog.category}`} sx={{ backgroundColor: "#76B687", color: "#fff" }} />
                    <Chip label={`Published on: ${formatDate(selectedBlog.created_at)}`} sx={{ backgroundColor: "#76B687", color: "#fff" }} />
                  </Box>
                  <Typography variant="caption" color="#666" fontWeight="bold" gutterBottom>
                      Share on Your Socials
                    </Typography>
                  <Box>
                            <Tooltip title="Share on Facebook">
                              <IconButton onClick={() => handleShare("facebook", selectedBlog.title, selectedBlog.content)} sx={{ color: "#3b5998" }}>
                                <Facebook />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Share on Twitter">
                              <IconButton onClick={() => handleShare("twitter", selectedBlog.title, selectedBlog.content)} sx={{ color: "#1DA1F2" }}>
                                <Twitter />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Share on LinkedIn">
                              <IconButton onClick={() => handleShare("linkedin", selectedBlog.title, selectedBlog.content)} sx={{ color: "#0077b5" }}>
                                <LinkedIn />
                              </IconButton>
                            </Tooltip>
                            {isMobile && (
                              <Tooltip title="Share on WhatsApp">
                                <IconButton onClick={() => handleShare("whatsapp", selectedBlog.title, selectedBlog.content)} sx={{ color: "#25D366" }}>
                                  <WhatsApp />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                </CardContent>
                <ScrollTopFab />
              </Card>
            )}
          </Grid>
          {!isMobile || !selectedBlog ? (
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: theme.spacing(2), maxHeight: "70vh", overflowY: "auto" }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Recent Articles
                </Typography>
                {blogPosts.slice(0, 5).map((post, index) => (
                  <Box key={index} sx={{ marginBottom: theme.spacing(1) }}>
                    <Typography
                      variant="subtitle1"
                      color="#666"
                      onClick={() => handleSelectBlog(post)}
                      sx={{ cursor: "pointer", "&:hover": { color: theme.palette.primary.main }, fontWeight: selectedBlog?.title === post.title ? "bold" : "normal" }}
                    >
                      {post.title}
                    </Typography>
                    {index < 4 && <Divider sx={{ bgcolor: "#F9A64F" }} />}
                  </Box>
                ))}
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Container>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
    </div>
  );
};

export default Community;
