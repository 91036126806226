import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Button,
  Grid,
  Box,
  Container,
  Modal,
  TextField,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link as MuiLink
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import SignupForm from "../components/SignupForm";
import serviceImage from "../assets/img2.png"; // Replace with actual path
import therapistImage from "../assets/img8.png"; // Replace with actual path
import wellnessImage from "../assets/img3.png"; // Additional image
import communityImage from "../assets/img9.png"; // Additional image
import { UserContext } from "../UserContext"; // Import user context
import Community from "./Community";
import Navbar from '../components/Navbar'

// Styled Components
const FlexContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  padding: "50px 20px",
  color: "#333",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "30px 10px",
  },
}));

const BlendedImage = styled(Box)(({ theme, backgroundImage }) => ({
  flex: '1',
  height: '400px',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundBlendMode: 'multiply',
  opacity: '0.85',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  width: '100%', 

  // Show image above text on smaller screens
  [theme.breakpoints.down('sm')]: {
    order: -1,
    height: '250px',
    minHeight: '250px',
    marginBottom: '15px',  // Add spacing below image if desired
  },
}));

const TextContent = styled(Box)(({ theme }) => ({
  flex: "1",
  textAlign: "left",
  padding: "20px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
}));



const Corporates = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext); // Use user context to manage authentication state
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [showBlog, setShowBlog] = useState(false); 

  const [activePage, setPage] = useState('Corporates');

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.slice(1) || 'Corporates';
    setPage(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    window.scrollTo(0, 0); // Scroll to top
    document.title = "Corporates | Cope Wellness"; // Update page title
  }, [location]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleExplore = () => navigate('/blog');

  // Handler for submitting sign-in credentials to corporate-auth
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      const response = await axios.post("https://your-api-url.com/corp-auth", { email, password });
      setUser(response.data.user); // Set user context after successful login
      setSnackbar({ open: true, message: "Signed in successfully", severity: "success" });
      handleCloseModal();
      navigate("/dashboard"); // Redirect to dashboard or profile page
    } catch (error) {
      console.error("Sign in error:", error);
      setSnackbar({ open: true, message: "Failed to sign in", severity: "error" });
    }
  };

  if (showBlog) return <Community />;

  return (
    <div>
      <Navbar />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "200px",
          bgcolor: "primary.main",
          color: "white",
          mb: 4,
        }}
      >
        <Typography variant="h3">Corporate Wellness</Typography>
        <Breadcrumbs
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            color: "white",
          }}
        >
          <MuiLink color="#f5f5f5" onClick={() => navigate('/')} sx={{cursor:'pointer'}}>
            Home
          </MuiLink>
          <Typography color="#fff">Corporates</Typography>
        </Breadcrumbs>
      </Box>

      <Container>
      <Grid container spacing={3} alignItems="start">
        {/* About Us Section */}
        <Grid item xs={12} md={8}>
            <FlexContainer>
              <BlendedImage backgroundImage={serviceImage} />
              <TextContent>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  About <span style={{ color: '#1a6051' }}>Cope</span>
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
                  We are dedicated to supporting corporate wellness by providing accessible, confidential,
                  and impactful wellness programs for employees. Our mission is to foster a healthier, more
                  productive workforce.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
                  Join hands with us in promoting workplace wellness. As a corporate partner, you can make a
                  lasting impact by offering your employees accessible wellness resources and fostering a culture
                  of mental well-being.
                </Typography>
              </TextContent>
            </FlexContainer>
          </Grid>

          {/* Sticky Call-to-Action Section */}
        <Grid item xs={12} md={4}>
          <SignupForm />
        </Grid>

          {/* Value Proposition Section */}
          <Grid item xs={12} md={10}>
            <FlexContainer>
              <TextContent>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                <span style={{ color: '#1a6051' }}>Cope's</span> Proposition
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
                  Our programs provide corporates with a seamless way to integrate mental health support into
                  their teams. Tailored, evidence-based support for your employees, anytime, anywhere.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
                  Be a part of a community that cares about mental health. Connect with others, access helpful
                  resources, and explore our blog for the latest insights in wellness and mental health.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleExplore}
                  sx={{ backgroundColor: "#00695C", color: "white", width: "100%", padding: "10px 20px" }}
                >
                  Explore
                </Button>
              </TextContent>
              <BlendedImage backgroundImage={therapistImage} />
            </FlexContainer>
          </Grid>


        
      </Grid>
      </Container>

      {/* Sign-In Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ p: 3, bgcolor: "background.paper", borderRadius: 1, maxWidth: 400, mx: "auto", mt: "20vh" }}>
          <Typography color="primary" variant="h6" align="center" gutterBottom>
            Corporate Sign-In
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="email"
              placeholder="Email"
              type="email"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              name="password"
              placeholder="Password"
              type="password"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Snackbar for Feedback */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Corporates;
