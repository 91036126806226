import React, { useState } from 'react';
import {
  AppBar, Toolbar, Button, Typography, Box, IconButton, SwipeableDrawer, List, ListItem, ListItemText, Modal, Snackbar, Alert
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/secondary_logo.png'; // Replace with your actual logo image path
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);  
  const [openTherapistModal, setOpenTherapistModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Open and close modals for Patients and Therapists
  const handleOpenPatientModal = () => setOpenPatientModal(true);
  const handleClosePatientModal = () => setOpenPatientModal(false);

  const handleOpenTherapistModal = () => setOpenTherapistModal(true);
  const handleCloseTherapistModal = () => setOpenTherapistModal(false);

  // Snackbar control
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSubmit = (event, type) => {
    event.preventDefault();
    setLoading(true);

    // Simulating API call
    setTimeout(() => {
      setLoading(false);
      setSnackbarMessage(`${type} registration successful!`);
      setSnackbarOpen(true);
      if (type === 'Patient') {
        handleClosePatientModal();
      } else {
        handleCloseTherapistModal();
      }
    }, 2000);
  };

  // Menu items
  const menuItems = [
    { text: 'Home', href: '#home' },
    { text: 'Services', href: '#services' },
    { text: 'Blog', href: '#blog' },
    { text: 'Corporates', href: '#corporates' },
    { text: 'Contact', href: '#contact' },
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#1a6051', padding: '10px 0' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
          </Box>

          {/* Menu items for desktop */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {menuItems.map((item, index) => (
              <Button
                key={index}
                color="inherit"
                href={item.href}
                sx={{
                  margin: '0 10px',
                  '&:hover': {
                    backgroundColor: '#fdbf57',
                    color: '#2a1a1a',
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* For Patients & For Therapists buttons */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#2a1a1a',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: '#fdbf57',
                  color: '#2a1a1a',
                },
              }}
              onClick={handleOpenPatientModal}
            >
              For Patients
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#e46e24',
                '&:hover': {
                  backgroundColor: '#fdbf57',
                  color: '#2a1a1a',
                },
              }}
              onClick={handleOpenTherapistModal}
            >
              For Therapists
            </Button>
          </Box>

          {/* Hamburger icon for mobile */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ ml: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Swipeable Drawer for mobile */}
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: '#1a6051', // Green background color for the drawer
            color: '#fff', // White text color
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {/* Logo inside drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
              <img src={logo} alt="Cope Logo" style={{ height: '50px' }} />
            </Box>

            {menuItems.map((item, index) => (
              <ListItem button key={index} component="a" href={item.href} onClick={toggleDrawer(false)}>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    style: { color: '#fdbf57', fontWeight: 'bold' }, // Gold color for menu item text
                  }}
                />
              </ListItem>
            ))}
            {/* For Patients button inside drawer */}
            <ListItem button onClick={handleOpenPatientModal}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#2a1a1a',
                  marginRight: '10px',
                  '&:hover': {
                    backgroundColor: '#fdbf57',
                    color: '#2a1a1a',
                  },
                  width: '100%',
                }}
              >
                For Patients
              </Button>
            </ListItem>
            {/* For Therapists button inside drawer */}
            <ListItem button onClick={handleOpenTherapistModal}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#e46e24',
                  '&:hover': {
                    backgroundColor: '#fdbf57',
                    color: '#2a1a1a',
                  },
                  width: '100%',
                }}
              >
                For Therapists
              </Button>
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>

      {/* Patient Sign-in Modal */}
      <Modal open={openPatientModal} onClose={handleClosePatientModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Patient Sign-in
          </Typography>
          <form onSubmit={(event) => handleSubmit(event, 'Patient')}>
            <TextField label="Email" fullWidth sx={{ marginBottom: '20px' }} required />
            <TextField label="Password" type="password" fullWidth sx={{ marginBottom: '20px' }} required />
            <Button variant="contained" fullWidth type="submit" sx={{ backgroundColor: '#4caf50' }} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Therapist Sign-in Modal */}
      <Modal open={openTherapistModal} onClose={handleCloseTherapistModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Therapist Sign-in
          </Typography>
          <form onSubmit={(event) => handleSubmit(event, 'Therapist')}>
            <TextField label="Email" fullWidth sx={{ marginBottom: '20px' }} required />
            <TextField label="Password" type="password" fullWidth sx={{ marginBottom: '20px' }} required />
            <Button variant="contained" fullWidth type="submit" sx={{ backgroundColor: '#4caf50' }} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Snackbar for Success Messages */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Navbar;
