import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Modal,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 
import { UserContext } from '../UserContext'; 

function SignupForm() {
  const navigate = useNavigate();
  const { login, user } = useContext(UserContext); 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    organization: "",
  });
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [signInData, setSignInData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", phoneNumber: "" }); // For storing validation errors

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle input changes with validations
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "phoneNumber") {
      let formattedValue = value.replace(/\D/g, '');

      if (formattedValue.startsWith("0")) {
        formattedValue = "254" + formattedValue.slice(1);
      } else if (!formattedValue.startsWith("254")) {
        formattedValue = "254" + formattedValue;
      }
      formattedValue = formattedValue.slice(0, 12);

      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: formattedValue.length !== 12 ? "Phone number must be 12 digits starting with 254." : ""
      }));
    } else if (name === "email") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: isValidEmail(value) ? "" : "Invalid email format.",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("https://api.cope.ke/corporates", formData);
      setSnackbar({ open: true, message: "Signup successful!", severity: "success" });
      setFormData({ name: "", email: "", phoneNumber: "", organization: "" });
    } catch (error) {
      console.error("Signup error:", error);
      setSnackbar({ open: true, message: "Signup failed. Please try again.", severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSignInChange = (event) => {
    const { name, value } = event.target;
    setSignInData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignInSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("https://api.cope.ke/api/auth", {
        ...signInData,
        corporate_login: true,
      });

      if (response.status === 200) {
        login(response.data);
        navigate("/corporate-profile");
        setSnackbar({ open: true, message: "Sign-in successful!", severity: "success" });
        setOpenModal(false);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data.message || error.response.data);
        setSnackbar({
          open: true,
          message: error.response.data.message || "Sign-in failed. Please try again.",
          severity: "error",
        });
      } else if (error.request) {
        console.error("No response received:", error.request);
        setSnackbar({
          open: true,
          message: "Unable to connect to server. Please try again later.",
          severity: "error",
        });
      } else {
        console.error("Sign-in error:", error.message);
        setSnackbar({
          open: true,
          message: "An unexpected error occurred. Please try again.",
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography textAlign="center" variant="h4" component="h1" gutterBottom color="#00695C">
        Join Us Today
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          placeholder="Your Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          placeholder="Phone Number (e.g., 254712345678)"
          name="phoneNumber"
          autoComplete="tel"
          value={formData.phoneNumber}
          onChange={handleChange}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="organization"
          placeholder="Organization"
          name="organization"
          autoComplete="organization"
          value={formData.organization}
          onChange={handleChange}
        />
        <Box sx={{ mt: 3, mb: 2, position: "relative" }}>
          <Button type="submit" fullWidth variant="contained" disabled={loading || !!errors.email || !!errors.phoneNumber}>
            {loading ? <CircularProgress size={24} /> : "Sign Up"}
          </Button>
        </Box>
      </Box>
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Already have an account?{" "}
        <Button variant="text" color="primary" onClick={() => setOpenModal(true)}>
          Sign In
        </Button>
      </Typography>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ p: 3, bgcolor: "background.paper", borderRadius: 1, maxWidth: 400, mx: "auto", mt: "20vh" }}>
          <Typography color="primary" variant="h6" align="center" gutterBottom>
            Corporate Sign-In
          </Typography>
          <form onSubmit={handleSignInSubmit}>
            <TextField
              name="email"
              placeholder="Email"
              type="email"
              fullWidth
              required
              value={signInData.email}
              onChange={handleSignInChange}
              sx={{ mb: 2 }}
            />
            <TextField
              name="password"
              placeholder="Password"
              type="password"
              fullWidth
              required
              value={signInData.password}
              onChange={handleSignInChange}
              sx={{ mb: 2 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
    </Container>
  );
}

export default SignupForm;
