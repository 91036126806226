import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Modal,
  Button,
  Breadcrumbs,
  Box,
  Link as MuiLink
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";
import ContactUs from "../components/ContactUs";
import ScrollTopFab from "../components/ScrollTopFab";

const navbarHeight = 64;

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: `${navbarHeight}px`,
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: 'url("https://source.unsplash.com/random?privacy")',
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const textStyle = {
  zIndex: 2,
  color: "white",
  fontSize: "48px",
  position: "relative",
};

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [showContacts, setShowContacts] = useState(false)

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleContacts = () => navigate('/contacts');

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
    document.title = "Privacy Policy | Cope Wellness"; // Update page title
  }, []);

  if (showContacts) return <ContactUs />;

  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "200px",
          bgcolor: "primary.main",
          color: "white",
          mb: 4,
        }}
      >
        <Typography variant="h3">Privacy Policy</Typography>
        <Breadcrumbs
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            color: "white",
          }}
        >
          <MuiLink color="#f5f5f5" onClick={() => navigate('/')} sx={{cursor:'pointer'}}>
            Home
          </MuiLink>
          <Typography color="#fff">Privacy Policy</Typography>
        </Breadcrumbs>
      </Box>       
      <Paper
        sx={{
          padding: 2,
          color: "#000",
          margin: "20px auto",
          maxWidth: "800px",
          boxShadow: "1px 1px 10px 0px grey",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "1px 1px 15px 0px teal",
          },
        }}
      >
        
        <Typography color="#00695C" textAlign={'center'} variant="h4" gutterBottom>
          Privacy Statement for Cope Wellness
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our platform.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We collect personal information such as name, email address, and other necessary details for account registration and service provision.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          2. Use of Your Information
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We use your information to provide, operate, and maintain our services, communicate with you, and improve our platform.
        </Typography>
        <Typography color="#00695C"  variant="h6" gutterBottom>
          3. Disclosure of Your Information
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We may share your information with third-party service providers to perform functions on our behalf. We do not sell or rent your personal information to third parties.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          4. Security of Your Information
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We employ administrative, technical, and physical security measures to protect your personal information. However, no security system is impenetrable.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          5. Your Data Protection Rights
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          You have the right to access, update, and delete your personal information. You can do so by contacting us directly.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          6. Changes to This Privacy Policy
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          If you have any questions about this privacy policy, please contact us.
        </Typography>
        <Button onClick={handleContacts} variant="contained" sx={{ backgroundColor: '#1a6051', color: '#fff', padding: '10px 20px' }}>
              Contact Us
        </Button>
        <ScrollTopFab />
      </Paper>
    </div>
  );
};

export default PrivacyPolicy;
