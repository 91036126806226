import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Image as ImageIcon
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserShopItems = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", price: "", image: "", description: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/shopitems");
      setItems(response.data);
      setFilteredItems(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch items data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredItems(
      items.filter(
        (item) =>
          item.name.toLowerCase().includes(value) ||
          item.description.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (item) => {
    setSelectedItem(item);
    setFormData(item);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleAddIconClick = () => {
    setFormData({ name: "", price: "", image: "", description: "" });
    setOpenAddModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/shopitems/${selectedItem.id}`, formData);
      fetchItems();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Item updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update item data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/shopitems/${selectedItem.id}`);
      fetchItems();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Item deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete item data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/shopitems", formData);
      fetchItems();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Item added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add item", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredItems.map((item) => (
        <Card key={item.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={item.image || ''}>
                {!item.image && <FontAwesomeIcon icon={faTag} size="2x" />}
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(item)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(item)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{item.name}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faDollarSign} /> {item.price}
            </Typography>
            <Typography variant="body2">
              {item.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Shop Items</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search items"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddIconClick}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          Add Item
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredItems.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No items found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Item</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price"
            name="price"
            value={formData.price || ""}
            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formData.description || ""}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
          <Button
            component="label"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Upload Image
            <ImageIcon sx={{ ml: 1 }} />
            <input
              type="file"
              hidden
              onChange={(e) => setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) })}
            />
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Item</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price"
            name="price"
            value={formData.price || ""}
            onChange={(e) => setFormData({ ...formData, price: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formData.description || ""}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
          <Button
            component="label"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Upload Image
            <ImageIcon sx={{ ml: 1 }} />
            <input
              type="file"
              hidden
              onChange={(e) => setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) })}
            />
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this item?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserShopItems;
