import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Fab
} from "@mui/material";
import { 
  Search as SearchIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  NoteAdd as NoteAddIcon, 
  Add as AddIcon,
  LocationOn as LocationOnIcon,
  Work as WorkIcon,
  LibraryBooks as LibraryBooksIcon,
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Upload as UploadIcon
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserClients = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openSpecializationModal, setOpenSpecializationModal] = useState(false);
  const [openResourceModal, setOpenResourceModal] = useState(false);
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/service_providers");
      setClients(response.data);
      setFilteredClients(response.data);  
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch clients data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredClients(
      clients.filter(
        (client) =>
          client.name.toLowerCase().includes(value) ||
          client.email.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (client) => {
    setSelectedClient(client);
    setFormData(client);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (client) => {
    setSelectedClient(client);
    setOpenDeleteModal(true);
  };

  const handleNotesIconClick = (client) => {
    setSelectedClient(client);
    setOpenNotesModal(true);
  };

  const handleAddNewClick = () => {
    setFormData({
      company_name: "",
      email: "",
      contact_person: "",
      phone: ""
    });
    setOpenAddModal(true);
  };

  const handleLocationIconClick = (client) => {
    setSelectedClient(client);
    setFormData({ location: "", latitude: "", longitude: "" });
    setOpenLocationModal(true);
  };

  const handleSpecializationIconClick = (client) => {
    setSelectedClient(client);
    setFormData({ specialization: "" });
    setOpenSpecializationModal(true);
  };

  const handleResourceIconClick = (client) => {
    setSelectedClient(client);
    setFormData({ resource: "" });
    setOpenResourceModal(true);
  };

  const handleLogoIconClick = (client) => {
    setSelectedClient(client);
    setOpenLogoModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Submitting form data: ", formData);
    try {
      await axios.put(`https://api.cope.ke/clients/${selectedClient.id}`, formData);
      fetchClients();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Client updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update client data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    console.log("Deleting client with id: ", selectedClient.id);
    try {
      await axios.delete(`https://api.cope.ke/clients/${selectedClient.id}`);
      fetchClients();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Client deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete client data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleNotesSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Adding notes: ", notes);
    try {
      await axios.post(`https://api.cope.ke/clients/${selectedClient.id}/notes`, { notes });
      fetchClients();
      setOpenNotesModal(false);
      setSnackbar({ open: true, message: "Notes added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add notes", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Adding new service provider: ", formData);
    try {
      await axios.post("http://34.30.141.248:5000/service_providers", formData);
      fetchClients();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Service provider added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add service provider", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleLocationSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Adding location: ", formData);
    try {
      await axios.post(`https://api.cope.ke/service_providers/${selectedClient.id}/locations`, formData);
      fetchClients();
      setOpenLocationModal(false);
      setSnackbar({ open: true, message: "Location added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add location", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleSpecializationSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Adding specialization: ", formData);
    try {
      await axios.post(`https://api.cope.ke/service_providers/${selectedClient.id}/specializations`, formData);
      fetchClients();
      setOpenSpecializationModal(false);
      setSnackbar({ open: true, message: "Specialization added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add specialization", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleResourceSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Adding resource: ", formData);
    try {
      await axios.post(`https://api.cope.ke/service_providers/${selectedClient.id}/resources`, formData);
      fetchClients();
      setOpenResourceModal(false);
      setSnackbar({ open: true, message: "Resource added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add resource", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleLogoSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Uploading logo: ", logo);
    const formData = new FormData();
    formData.append("logo", logo);
    try {
      await axios.post(`https://api.cope.ke/clients/${selectedClient.id}/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchClients();
      setOpenLogoModal(false);
      setSnackbar({ open: true, message: "Logo uploaded successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to upload logo", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredClients.map((client) => (
        <Card key={client.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={client.logo || ''}>
                {!client.logo && <FontAwesomeIcon icon={faUser} size="2x" />}
              </Avatar>
              <Tooltip title="Upload Logo">
                <IconButton onClick={() => handleLogoIconClick(client)}>
                  <UploadIcon />
                </IconButton>
              </Tooltip>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(client)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(client)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Notes">
                  <IconButton onClick={() => handleNotesIconClick(client)}>
                    <NoteAddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Location">
                  <IconButton onClick={() => handleLocationIconClick(client)}>
                    <LocationOnIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Specialization">
                  <IconButton onClick={() => handleSpecializationIconClick(client)}>
                    <WorkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Resources">
                  <IconButton onClick={() => handleResourceIconClick(client)}>
                    <LibraryBooksIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{client.name}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {client.email}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faPhone} /> {client.phone}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {client.status === "inactive" ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container  sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>Clients</Typography>
        <Tooltip title="How to use">
          <IconButton onClick={() => setOpenInfoModal(true)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Back">
          <IconButton onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search clients"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewClick}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          Add New
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredClients.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No clients found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Client</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formData.phone || ""}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="address"
            label="Address"
            name="address"
            value={formData.address || ""}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="specialization"
            label="Specialization"
            name="specialization"
            value={formData.specialization || ""}
            onChange={(e) => setFormData({ ...formData, specialization: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="status"
            label="Status"
            name="status"
            value={formData.status || ""}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Register Your Account</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="company_name"
            label="Company Name"
            name="company_name"
            value={formData.company_name || ""}
            onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="contact_person"
            label="Contact Person"
            name="contact_person"
            value={formData.contact_person || ""}
            onChange={(e) => setFormData({ ...formData, contact_person: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formData.phone || ""}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openLocationModal} onClose={() => setOpenLocationModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleLocationSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Location</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="location"
            label="Location"
            name="location"
            value={formData.location || ""}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="latitude"
            label="Latitude"
            name="latitude"
            value={formData.latitude || ""}
            onChange={(e) => setFormData({ ...formData, latitude: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="longitude"
            label="Longitude"
            name="longitude"
            value={formData.longitude || ""}
            onChange={(e) => setFormData({ ...formData, longitude: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openSpecializationModal} onClose={() => setOpenSpecializationModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleSpecializationSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Specialization</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="specialization"
            label="Specialization"
            name="specialization"
            value={formData.specialization || ""}
            onChange={(e) => setFormData({ ...formData, specialization: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openResourceModal} onClose={() => setOpenResourceModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleResourceSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Resource</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="resource"
            label="Resource"
            name="resource"
            value={formData.resource || ""}
            onChange={(e) => setFormData({ ...formData, resource: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openNotesModal} onClose={() => setOpenNotesModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleNotesSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Notes</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openLogoModal} onClose={() => setOpenLogoModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleLogoSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Upload Logo</Typography>
          <input
            accept="image/*"
            type="file"
            onChange={handleLogoChange}
            style={{ margin: '20px 0' }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this client?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>How to Use Clients Screen</Typography>
          <Typography variant="body1" gutterBottom>
            <ul>
              <li>Use the search bar to filter clients by name or email.</li>
              <li>Click on the icons next to a client to perform actions like editing, deleting, adding notes, locations, specializations, or resources.</li>
              <li>Click on the "Add New" button to register a new service provider.</li>
              <li>Use the back button to navigate to the previous page.</li>
              <li>Click on the information icon to view this help modal.</li>
            </ul>
          </Typography>
          <Button onClick={() => setOpenInfoModal(false)} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Tooltip title="Return to Top">
        <Fab
          color="primary"
          onClick={handleScrollToTop}
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserClients;
