import React, { useState, useContext, useEffect } from 'react';
import {
  List, ListItem, ListItemText, ListItemIcon, Divider, Link, Collapse, Badge
} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event'; // Appointments
import AssignmentIcon from '@mui/icons-material/Assignment'; // Assessments
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'; // Treatment
import HealingIcon from '@mui/icons-material/Healing'; // Post Recovery
import GroupIcon from '@mui/icons-material/Group'; // Sessions
import ReceiptIcon from '@mui/icons-material/Receipt'; // Billing (Financials)
import SupportIcon from '@mui/icons-material/Support'; // Ticketing (Administration)
import PeopleIcon from '@mui/icons-material/People'; // Clients (Administration)
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Calendar
import SettingsIcon from '@mui/icons-material/Settings'; // Settings
import PersonIcon from '@mui/icons-material/Person'; // Your Profile (Settings)
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext'; // Import UserContext for logout
import axios from 'axios'; // For fetching API data

const ServiceProviderMenu = ({ handleDashboardViewClick }) => {
  const { logout } = useContext(UserContext); // Access the logout function from context
  const navigate = useNavigate(); // For navigation after logout

  // States for handling dropdowns
  const [openTherapy, setOpenTherapy] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openFinancials, setOpenFinancials] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  // States for holding status counts fetched from APIs
  const [appointmentsStatus, setAppointmentsStatus] = useState(0);
  const [sessionsStatus, setSessionsStatus] = useState(0);
  const [treatmentStatus, setTreatmentStatus] = useState(0);
  const [postRecoveryStatus, setPostRecoveryStatus] = useState(0);
  const [ticketingStatus, setTicketingStatus] = useState(0);
  const [clientsStatus, setClientsStatus] = useState(0);
  const [staffStatus, setStaffStatus] = useState(0);
  const [financialsStatus, setFinancialsStatus] = useState(0);
  const [calendarStatus, setCalendarStatus] = useState(0);

  // Fetch status counts from respective endpoints
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const appointmentsResponse = await axios.get('https://cope.ke/appointments?status=pending');
        const sessionsResponse = await axios.get('https://cope.ke/sessions?status=completed');
        const treatmentResponse = await axios.get('https://cope.ke/treatments?status=pending');
        const postRecoveryResponse = await axios.get('https://cope.ke/post-recovery?status=new');
        const ticketingResponse = await axios.get('https://cope.ke/tickets?status=closed');
        const clientsResponse = await axios.get('https://cope.ke/clients?status=is_active');
        const staffResponse = await axios.get('https://cope.ke/staff?status=is_active');
        const financialsResponse = await axios.get('https://cope.ke/financials?status=active');
        const calendarResponse = await axios.get('https://cope.ke/calendar?status=completed');

        setAppointmentsStatus(appointmentsResponse.data.count || 0);
        setSessionsStatus(sessionsResponse.data.count || 0);
        setTreatmentStatus(treatmentResponse.data.count || 0);
        setPostRecoveryStatus(postRecoveryResponse.data.count || 0);
        setTicketingStatus(ticketingResponse.data.count || 0);
        setClientsStatus(clientsResponse.data.count || 0);
        setStaffStatus(staffResponse.data.count || 0);
        setFinancialsStatus(financialsResponse.data.count || 0);
        setCalendarStatus(calendarResponse.data.count || 0);
      } catch (error) {
        console.error('Error fetching status data:', error);
      }
    };

    fetchStatuses();
  }, []);

  // Toggle dropdowns
  const handleTherapyClick = () => setOpenTherapy(!openTherapy);
  const handleAdminClick = () => setOpenAdmin(!openAdmin);
  const handleFinancialsClick = () => setOpenFinancials(!openFinancials);
  const handleSettingsClick = () => setOpenSettings(!openSettings);

  // Function to handle logout
  const handleLogout = () => {
    logout(); // Log out the user via context
    navigate('/'); // Redirect to login or home page after logout
  };

  return (
    <List component="nav" aria-label="service provider menu">
      {/* Dashboard */}
      <ListItem button onClick={() => navigate('/service-dashboard')} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <InboxIcon /> {/* Icon for Inbox */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Your Dashboard" />
      </ListItem>

      {/* Therapy Dropdown */}
      <ListItem button onClick={handleTherapyClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <DashboardIcon /> {/* Icon for Therapy */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Therapy" />
        {openTherapy ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openTherapy} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-appointments')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={appointmentsStatus} color="secondary">
                <EventIcon /> {/* Icon for Appointments */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Appointments" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-sessions')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={sessionsStatus} color="secondary">
                <GroupIcon /> {/* Icon for Sessions */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Sessions" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-treatments')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={treatmentStatus} color="secondary">
                <LocalHospitalIcon /> {/* Icon for Treatment */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Treatment" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-post-recovery')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={postRecoveryStatus} color="secondary">
                <HealingIcon /> {/* Icon for Post Recovery */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Post Recovery" />
          </ListItem>
        </List>
      </Collapse>

      {/* Administration Dropdown */}
      <ListItem button onClick={handleAdminClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SupportIcon /> {/* Icon for Administration */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Administration" />
        {openAdmin ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-tickets')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={ticketingStatus} color="secondary">
                <AssignmentIcon /> {/* Icon for Ticketing */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Ticketing" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-clients')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={clientsStatus} color="secondary">
                <PeopleIcon /> {/* Icon for Clients */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Clients" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-staff')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={staffStatus} color="secondary">
                <PeopleIcon /> {/* Icon for Staff */}
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Staff" />
          </ListItem>
        </List>
      </Collapse>

      {/* Financials Dropdown */}
      <ListItem button onClick={handleFinancialsClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <Badge badgeContent={financialsStatus} color="secondary">
            <ReceiptIcon /> {/* Icon for Financials */}
          </Badge>
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Financials" />
        {openFinancials ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openFinancials} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-billing')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <ReceiptIcon /> {/* Icon for Billing */}
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Billing" />
          </ListItem>
        </List>
      </Collapse>

      {/* Calendar */}
      <ListItem button onClick={() => navigate('/sp-calendar')} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <Badge badgeContent={calendarStatus} color="secondary">
            <CalendarTodayIcon /> {/* Icon for Calendar */}
          </Badge>
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Calendar" />
      </ListItem>

      {/* Settings Dropdown */}
      <ListItem button onClick={handleSettingsClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SettingsIcon /> {/* Icon for Settings */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Settings" />
        {openSettings ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={() => navigate('/sp-settings')}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <PersonIcon /> {/* Icon for Your Profile */}
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Your Profile" />
          </ListItem>
        </List>
      </Collapse>

      <Divider />

      {/* Logout */}
      <ListItem button onClick={handleLogout} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ExitToAppIcon /> {/* Icon for Exit/Logout */}
        </ListItemIcon>
        <ListItemText
          sx={{ color: '#00695C' }}
          primary={
            <Link
              to="#"
              onClick={handleLogout}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              Sign Out
            </Link>
          }
        />
      </ListItem>
    </List>
  );
};

export default ServiceProviderMenu;
