import React, { useState, useEffect, useContext } from "react"; 
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as ViewIcon,
  Payment as PaymentIcon,
  Download as DownloadIcon,
  CheckCircle as ConfirmIcon,
  Cancel as CancelIcon
} from "@mui/icons-material";
import axios from "axios";
import { UserContext } from "../UserContext";

const UserBilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useContext(UserContext);
  const [bills, setBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/user-billing/${user.user_id}`);
      const invoiceData = response.data.map(bill => ({
        ...bill,
        plans: JSON.parse(bill.plans) // Parse the JSON string in each bill’s `plans` field
      }));
      setBills(invoiceData);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch bills", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
  };

  const handleRowClick = (bill) => {
    setSelectedBill(bill);
  };

  const handleBackClick = () => {
    setSelectedBill(null);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const handlePaymentDialogOpen = () => {
    setShowPaymentDialog(true);
  };

  const handlePaymentDialogClose = () => {
    setShowPaymentDialog(false);
  };

  const handleConfirmPayment = async () => {
    if (paymentOption === "mpesa") {
      try {
        const amountDue = selectedBill.plans.reduce((sum, plan) => sum + plan.price, 0);
        const paymentData = {
          invoiceId: selectedBill.invoice_no,
          phone_number: selectedBill.phone,
          amount: amountDue,
          payment_option: paymentOption
        };

        await axios.post(`https://api.cope.ke/stk_push`, paymentData);
        alert("Payment initiated successfully via Mpesa.");
      } catch (error) {
        alert("Failed to initiate payment. Please try again.");
      }
    }
    handlePaymentDialogClose();
  };

  const calculateTotalWithVAT = (plans) => {
    const subtotal = plans.reduce((sum, plan) => sum + plan.price, 0);
    const vat = subtotal * 0.16;
    return {
      subtotal: subtotal.toFixed(2),
      vat: vat.toFixed(2),
      total: (subtotal + vat).toFixed(2)
    };
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Invoice No</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.length > 0 ? (
            bills.map((bill) => (
              <TableRow key={bill.billing_id} onClick={() => handleRowClick(bill)}>
                <TableCell>{bill.billing_date ? new Date(bill.billing_date).toLocaleDateString() : "N/A"}</TableCell>
                <TableCell>{bill.invoice_no}</TableCell>
                <TableCell>{bill.plans.map(plan => plan.plan_type).join(", ")}</TableCell>
                <TableCell>
                  <Chip label={bill.status} color={bill.status === "Paid" ? "success" : "warning"} />
                </TableCell>
                <TableCell>Ksh {bill.plans.reduce((sum, plan) => sum + plan.price, 0).toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleRowClick(bill); }} sx={{ color: '#00695C' }}>
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No invoice records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => {
    const { subtotal, vat, total } = calculateTotalWithVAT(selectedBill.plans);

    return (
      <Box>
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Invoice Details</Typography>
        <Box sx={{ mt: 2 }}>
          <Typography><strong>Invoice No:</strong> {selectedBill.invoice_no}</Typography>
          <Typography><strong>Date:</strong> {selectedBill.billing_date ? new Date(selectedBill.billing_date).toLocaleDateString() : "N/A"}</Typography>
          <Typography><strong>Status:</strong> <Chip label={selectedBill.status} color={selectedBill.status === "Paid" ? "success" : "warning"} /></Typography>
          
          {/* Invoice Breakdown Table */}
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Plan Type</strong></TableCell>
                  <TableCell align="right"><strong>Price (Ksh)</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedBill.plans.map((plan, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{plan.plan_type}</TableCell>
                    <TableCell align="right">{plan.price.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell><strong>Subtotal</strong></TableCell>
                  <TableCell align="right">Ksh {subtotal}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>VAT (16%)</strong></TableCell>
                  <TableCell align="right">Ksh {vat}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell align="right">Ksh {total}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Download and Payment Icons Centered Below the Table */}
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 3 }}>
            <Tooltip title="Download Invoice">
              <IconButton onClick={() => downloadInvoice(selectedBill)} sx={{ color: '#00695C' }}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            {selectedBill.status === "Pending" && (
              <Tooltip title="Make Payment">
                <IconButton onClick={handlePaymentDialogOpen} sx={{ color: '#00695C' }}>
                  <PaymentIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const downloadInvoice = (bill) => {
    alert(`Downloading invoice for ${bill.invoice_no}`);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Your Bills</Typography>
      {!selectedBill && (
        <Toolbar>
          <TextField
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search bills"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      )}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedBill ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Dialog open={showPaymentDialog} onClose={handlePaymentDialogClose}>
        <DialogTitle>Select Payment Option</DialogTitle>
        <DialogContent>
          <RadioGroup value={paymentOption} onChange={handlePaymentOptionChange}>
            <FormControlLabel value="mpesa" control={<Radio sx={{ color: '#2C1C18' }} />} label="Mpesa" />
            <FormControlLabel value="bank" control={<Radio sx={{ color: '#2C1C18' }} />} label="Bank Transfer" />
          </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
          <Tooltip title="Cancel">
            <IconButton onClick={handlePaymentDialogClose} sx={{ color: '#2C1C18' }}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Confirm Payment">
            <IconButton onClick={handleConfirmPayment} sx={{ color: '#00695C' }} disabled={!paymentOption}>
              <ConfirmIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserBilling;
