import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom'; // <-- Import useParams
import axios from 'axios';
import logo from '../assets/logo.png';

const PaymentConfirmation = () => {
  const { invoice_id } = useParams(); // Get invoice_id from the URL params

  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [stkPushLoading, setStkPushLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const response = await axios.get(`https://api.cope.ke/billing/${invoice_id}`);
        const invoiceData = response.data[0];
        
        if (invoiceData.plans) {
          invoiceData.plans = JSON.parse(invoiceData.plans);
        }
        setInvoiceDetails(invoiceData);
        setPhoneNumber(formatPhoneNumber(invoiceData.phone));
        setLoading(false);
      } catch (error) {
        setSnackbarMessage('Failed to load invoice details.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoading(false);
      }
    };
    fetchInvoiceDetails();
  }, [invoice_id]);

  // Updated to remove '+' symbol from the phone number
  const formatPhoneNumber = (number) => {
    if (number.startsWith('+254')) {
      return number.slice(1); // Remove the '+' from +254...
    } else if (number.startsWith('07')) {
      return '254' + number.slice(1); // Convert 07... to 2547...
    }
    return number;
  };

  const handlePaymentOptionChange = (event) => {
    setSelectedPaymentOption(event.target.value);
  };

  const handlePaymentSubmit = () => {
    if (!selectedPaymentOption) {
      setSnackbarMessage('Please select a payment option.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setConfirmModalOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setStkPushLoading(true);
    try {
      const paymentData = {
        invoiceId: invoice_id,
        payment_option: selectedPaymentOption,
        phone_number: phoneNumber,
        amount: totalAmount,
      };

      const response = await axios.post(`https://api.cope.ke/stk_push`, paymentData);

      if (response.data.success) {
        setSnackbarMessage('Payment successful!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        await axios.post(`/payments/confirm`, { invoiceId: invoice_id });
        window.location.href = `/session/${invoice_id}`;
        setConfirmModalOpen(false);
      } else {
        setSnackbarMessage('Payment failed. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setConfirmModalOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error processing payment.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setConfirmModalOpen(true);
    } finally {
      setStkPushLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const totalAmount = invoiceDetails.plans.reduce((acc, plan) => acc + plan.price, 0);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 1 }}>
        <img src={logo} alt="Cope Wellness Logo" style={{ width: '150px' }} />
      </Box>

      <Typography variant="h5" color="#00695C" align="center" gutterBottom>
        Payment Confirmation
      </Typography>

      <Box mt={4}>
        <Typography color="#00695C" variant="h6">Patient Details:</Typography>
        <Typography variant="body1">
          <strong>Name:</strong> {invoiceDetails.name}
        </Typography>
        <Typography variant="body1">
          <strong>Email:</strong> {invoiceDetails.email}
        </Typography>
        <Typography variant="body1">
          <strong>Phone:</strong> {invoiceDetails.phone}
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography color="#00695C" variant="h6">Invoice Details:</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Plan</strong></TableCell>
                <TableCell><strong>Price (Ksh)</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceDetails.plans && invoiceDetails.plans.map((plan, index) => (
                <TableRow key={index}>
                  <TableCell>{plan.plan_type}</TableCell>
                  <TableCell>{plan.price}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell><strong>Total Amount</strong></TableCell>
                <TableCell><strong>Ksh {totalAmount}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={4}>
        <Card>
          <CardContent>
            <Typography color="#00695C" variant="h6" gutterBottom>
              Select Payment Method:
            </Typography>
            <RadioGroup
              aria-label="payment-options"
              value={selectedPaymentOption}
              onChange={handlePaymentOptionChange}
            >
              <FormControlLabel value="mpesa" control={<Radio />} label="Mpesa" />
              <FormControlLabel value="bank" control={<Radio />} label="Bank Transfer" />
            </RadioGroup>
          </CardContent>
        </Card>
      </Box>

      <Box mt={4} mb={4} display="flex" justifyContent="center">
        <Button variant="contained" onClick={handlePaymentSubmit}>
          Submit Payment
        </Button>
      </Box>

      <Dialog open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)} fullWidth>
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            <strong>Total Amount:</strong> Ksh {totalAmount}
          </Typography>
          <TextField
            label="Phone Number"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))}
            margin="dense"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSubmit} color="primary" variant="contained" disabled={stkPushLoading}>
            {stkPushLoading ? <CircularProgress size={24} /> : 'Confirm Payment'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PaymentConfirmation;
