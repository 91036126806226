import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00695C', // Teal
    },
    secondary: {
      main: '#76B687', // Mint
    },
    accent: {
      main: '#F9A64F', // Amber
    },
    background: {
      default: '#F5F5F5', // Off-White background
    },
    text: {
      primary: '#2C1C18', // Charcoal Brown for text
      secondary: '#FFFFFF', // White for contrast with dark backgrounds
      placeholder: '#888888', // Change this color for placeholders
    },
  },
  typography: {
    fontFamily: 'Cabinet Grotesk, sans-serif', 
    // your other typography styles
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#00695C', 
          color: '#FFFFFF', // White text
          '&:hover': {
            backgroundColor: '#e46e24', 
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '::name': {
            color: '#00695C', // Light grey color for placeholders
            opacity: 1, // Ensure it is fully opaque
          },
        },
        
      },
    },
    // your other component overrides
  },
});

export default theme;
