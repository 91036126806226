import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  IconButton,
  Snackbar,
  CircularProgress,
  Alert,
  Link as MuiLink,
  Breadcrumbs
} from "@mui/material";
import Navbar from "./Navbar";
import logo from "../assets/logo.png";
import SocialMediaLinks from "./SocialMediaLinks";
import { Email, Phone, LocationOn, Instagram } from "@mui/icons-material";
import axios from "axios";

function ContactUs() {
  const navbarHeight = 64;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const [activePage, setPage] = useState('Contact Us');

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.slice(1) || 'Contact Us';
    setPage(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    window.scrollTo(0, 0); // Scroll to top
    document.title = "Contact Us | Cope Wellness"; // Update page title
  }, [location]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSnackbar({ open: false, message: "", severity: "success" });

    try {
      await axios.post("https://api.cope.ke/contact-us", {
        ...formData,
        to: "hello@cope.ke",
        cc: "therapycope@gmail.com",
      });
      setSnackbar({ open: true, message: "Message sent successfully!", severity: "success" });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbar({ open: true, message: "Failed to send message. Please try again.", severity: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });


  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: `${navbarHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Navbar />
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "200px",
          bgcolor: "primary.main",
          color: "white",
          mb: 4,
        }}
      >
        <Typography variant="h3">Contact Us</Typography>
        <Breadcrumbs
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            color: "white",
          }}
        >
          <MuiLink color="#f5f5f5" onClick={() => navigate('/')} sx={{cursor:'pointer'}}>
            Home
          </MuiLink>
          <Typography color="#fff">Contact Us</Typography>
        </Breadcrumbs>
      </Box>
      <Container>
        <Grid container spacing={4} pt={4}>
          {/* Company information and images */}
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h4" gutterBottom color="#00695C">
              About Our Company
            </Typography>
            <Typography color="#666" variant="body1" paragraph>
            Cope offers flexible online therapy options designed to meet diverse mental health needs—from individuals and couples to families, children, and corporate teams. Our certified professionals provide personalized support through one-on-one sessions, group workshops, and family-focused therapy.
            </Typography>
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "50%",
                borderRadius: "4px",
              }}
              alt="Company image"
              src={logo} // Replace with the path to your image
            />
           
            {/* Contact Details */}
            <Box mt={3} >
              <Box display="flex" mb={1}>
                <Email sx={{ color: "teal", mr: 1 }} />
                <Typography variant="body2" color="#666">
                  hello@cope.ke
                </Typography>
              </Box>
              <Box display="flex"  mb={1}>
                <Phone sx={{ color: "teal", mr: 1 }} />
                <Typography variant="body2" color="#666">
                  +254 700 000000
                </Typography>
              </Box>
              <Box display="flex" >
                <LocationOn sx={{ color: "teal", mr: 1 }} />
                <Typography variant="body2" color="#666">
                  Nairobi, Kenya
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Contact form */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
              <Typography variant="h5" gutterBottom color="#00695C">
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  placeholder="Name"
                  name="name"
                  variant="outlined"
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  placeholder="Email"
                  name="email"
                  variant="outlined"
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  placeholder="Message"
                  name="message"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Sending..." : "Send"}
                </Button>
              </form>
            </Paper>
          </Grid>

        </Grid>
      </Container>
      {/* Snackbar for feedback */}
      <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
    </div>
  );
}

export default ContactUs;
