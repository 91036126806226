import React from 'react';
import { Box, Typography, Grid, IconButton, Link } from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';
import logo from '../assets/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const Footer = ({ setPage }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the active link based on the current route
  const isActive = (path) => location.pathname === path;

  const handleNavigation = (path) => {
    setPage(path);
    navigate(`/${path.toLowerCase()}`);
  };

  return (
    <Box sx={{ backgroundColor: '#f1f1f1', padding: '30px 20px', borderTop: '1px solid #e0e0e0' }}>
      {/* Top section with logo, navigation links, and social media icons */}
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: '20px' }}>
        
        {/* Left side: Logo */}
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' }, marginBottom: { xs: '20px', md: '0' } }}>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center' }}>
            <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
          </Box>
        </Grid>

        {/* Center: Navigation Links */}
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Link
            onClick={() => handleNavigation('Terms')}
            underline="none"
            sx={{
              margin: '0 15px',
              color: isActive('/terms') ? '#000' : '#666',
              '&:hover': { color: '#000' },
              cursor: 'pointer',
              fontWeight: isActive('/terms') ? 'bold' : 'normal',
            }}
          >
            Terms
          </Link>
          <Link
            onClick={() => handleNavigation('privacy-policy')}
            underline="none"
            sx={{
              margin: '0 15px',
              color: isActive('/privacy-policy') ? '#000' : '#666',
              '&:hover': { color: '#000' },
              cursor: 'pointer',
              fontWeight: isActive('/privacy-policy') ? 'bold' : 'normal',
            }}
          >
            Privacy Policy
          </Link>
          <Link
            onClick={() => handleNavigation('Contacts')}
            underline="none"
            sx={{
              margin: '0 15px',
              color: isActive('/contacts') ? '#000' : '#666',
              '&:hover': { color: '#000' },
              cursor: 'pointer',
              fontWeight: isActive('/contacts') ? 'bold' : 'normal',
            }}
          >
            Contacts
          </Link>
        </Grid>

        {/* Right side: Social Media Icons */}
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
          <IconButton
            href="https://www.instagram.com/cope.ke/"
            sx={{
              color: '#00695C',
              '&:hover': {
                backgroundColor: '#e1306c', // Instagram brand color
                color: '#fff',
              },
            }}
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            href="https://www.facebook.com/cope.ke"
            sx={{
              color: '#00695C',
              '&:hover': {
                backgroundColor: '#3b5998', // Facebook brand color
                color: '#fff',
              },
            }}
          >
            <FaFacebook />
          </IconButton>
          <IconButton
            href="https://www.twitter.com/cope.ke"
            sx={{
              color: '#00695C',
              '&:hover': {
                backgroundColor: '#1DA1F2', // Twitter brand color
                color: '#fff',
              },
            }}
          >
            <FaTwitter />
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/company/cope-ke"
            sx={{
              color: '#00695C',
              '&:hover': {
                backgroundColor: '#0077b5', // LinkedIn brand color
                color: '#fff',
              },
            }}
          >
            <FaLinkedin />
          </IconButton>
          <IconButton
            href="https://www.tiktok.com/@cope.ke"
            sx={{
              color: '#00695C',
              '&:hover': {
                backgroundColor: '#000', // TikTok brand color
                color: '#fff',
              },
            }}
          >
            <FaTiktok />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  setPage: PropTypes.func.isRequired,
};

export default Footer;
