import React, { useState, useEffect } from 'react';
import { Fab, Tooltip } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';

const ScrollTopFab = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle visibility of FAB when scrolling
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        right: 20,
        bottom: 150,
        zIndex: 1000,
        display: isVisible ? 'block' : 'none',
      }}
    >
      <Tooltip title="Return to Top">
        <Fab color="primary" onClick={scrollToTop}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
};

export default ScrollTopFab;
