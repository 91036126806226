import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import logo from "../assets/logo.png"; // Ensure you have the logo image

const AdminLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [resetEmail, setResetEmail] = useState("");
  const [showReset, setShowReset] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleResetChange = (event) => {
    setResetEmail(event.target.value);
  };

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.cope.ke/api/auth",
        { ...formData, admin: true }
      );
      const account = response.data;
      if (account) {
        setSuccess("Login successful! Redirecting...");
        navigate("/admin-profile", { state: { res_admin: account } });
      }
    } catch (error) {
      setError("Invalid credentials or error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.post("https://api.cope.ke/auth/reset-password", {
        email: resetEmail,
      });
      setShowReset(false);
      setSuccess("Password reset email sent.");
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setError("");
    setSuccess("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Paper
        style={{
          backgroundColor: "#F5F5F5", // Paper background color
          color: "#00695C",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!showReset ? (
          <Box
            sx={{
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 2,
              overflowY: "auto",
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ display: "block", marginBottom: "20px", maxWidth: "80px" }}
            />
            <Typography id="modal-modal-title" variant="h6" component="h4" align="center">
              System Administration
            </Typography>
            <Box component="form" onSubmit={handleSignInWithEmail} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formData.email}
                onChange={handleChange}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#00695C" }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
              </Button>
              <Grid container justifyContent="space-between">
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => setShowReset(true)}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "90%", sm: "80%", md: "60%", lg: "30%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3, // Adjust padding to remove extra space
              borderRadius: 2,
              overflowY: "auto",
              maxHeight: "90vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ display: "block", marginBottom: "20px", maxWidth: "80px" }}
            />
            <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleResetSubmit}
              sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }} // Ensure full-width TextField
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                id="email"
                label="Your Email"
                name="email"
                type="email"
                fullWidth // Make sure TextField is full width
                value={resetEmail}
                onChange={handleResetChange}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}> {/* Align button properly */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#00695C" }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                </Button>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}> {/* Ensure proper alignment for back link */}
                <Link href="#" variant="body2" onClick={() => setShowReset(false)} sx={{ textAlign: "center" }}>
                  Back to Sign In
                </Link>
              </Box>
            </Box>
          </Box>
        )}

        {/* Snackbar for error or success messages */}
        <Snackbar
          open={Boolean(error) || Boolean(success)}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"} sx={{ width: "100%" }}>
            {error || success}
          </Alert>
        </Snackbar>
      </Paper>
    </div>
  );
};

export default AdminLogin;
