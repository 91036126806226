import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Schedule as RescheduleIcon,
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';

const ServiceProviderTherapySessions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    status: ''
  });

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await axios.get('https://api.cope.ke/api/therapy-sessions');
      setSessions(response.data);
      setFilteredSessions(response.data);
      setLoading(false);
    } catch (error) {
      handleError('Failed to fetch sessions');
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSessions(
      sessions.filter(
        (session) =>
          session.name.toLowerCase().includes(value) ||
          session.email.toLowerCase().includes(value) ||
          session.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (session) => {
    setSelectedSession(session);
    setFormData(session);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedSession(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
  };

  const handleOpenAddEditModal = (session = null) => {
    setSelectedSession(session);
    setFormData(session || {
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedSession(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedSession) {
        await axios.put(`https://api.cope.ke/therapy_sessions/${selectedSession.id}`, formData);
      } else {
        await axios.post('https://api.cope.ke/therapy_sessions', formData);
      }
      fetchSessions();
      handleCloseAddEditModal();
    } catch (error) {
      handleError('Failed to save session');
    }
  };

  const handleDelete = async (sessionId) => {
    try {
      await axios.delete(`https://api.cope.ke/therapy_sessions/${sessionId}`);
      fetchSessions();
    } catch (error) {
      handleError('Failed to delete session');
    }
  };

  const handleReschedule = async (sessionId, newDate) => {
    try {
      await axios.put(`https://api.cope.ke/therapy_sessions/${sessionId}`, { date: newDate });
      fetchSessions();
    } catch (error) {
      handleError('Failed to reschedule session');
    }
  };

  const handleCancel = async (sessionId) => {
    try {
      await axios.put(`https://api.cope.ke/therapy_sessions/${sessionId}`, { status: 'Cancelled' });
      fetchSessions();
    } catch (error) {
      handleError('Failed to cancel session');
    }
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSessions.length > 0 ? (
            filteredSessions.map((session) => (
              <TableRow key={session.session_id} onClick={() => handleOpenDetailsModal(session)}>
                <TableCell>{session.session_type}</TableCell>
                <TableCell>{new Date(session.session_date).toLocaleString()}</TableCell>
                
                <TableCell>{session.status}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(session); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleReschedule(session.id, session.date); }}>
                    <RescheduleIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleCancel(session.id); }}>
                    <CancelIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(session.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} sx={{color:'red'}} align="center">
                No sessions available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Session Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          type="tel"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="datetime-local"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color='#00695C' variant="h4" gutterBottom>Therapy Sessions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search sessions"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddEditModal()}
          sx={{ ml: 2 }}
        >
          Add
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedSession ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openAddEditModal} onClose={handleCloseAddEditModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>

      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderTherapySessions;
