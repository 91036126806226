import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your tracking ID
ReactGA.initialize('G-MB7EYEX69K'); // Replace 'G-XXXXXXXXXX' with your GA4 tracking ID

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Track the current page view when the location changes
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return null;
};

export default useAnalytics;
