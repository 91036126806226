import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Chip,
  Card,
  CardContent,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  Visibility as ViewIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Event as RescheduleIcon,
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import axios from "axios";
import { addDays, format, isBefore,  parse, isValid } from "date-fns";
import { UserContext } from "../UserContext";

const UserAppointment = () => {
  const { user } = useContext(UserContext);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(format(addDays(new Date(), 1), "yyyy-MM-dd"));
  const [appointmentTime, setAppointmentTime] = useState("09:00");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.cope.ke/appointments/${user.user_id}`);
      
      setAppointments(response.data);
    } catch (error) {
      console.error("Failed to fetch appointments", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteConfirmation = () => {
    setOpenConfirmDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedAppointment) {
      try {
        await axios.delete(`https://api.cope.ke/appointments/${selectedAppointment.appointment_id}`);
        setSnackbar({ open: true, message: "Appointment deleted successfully!", severity: "success" });
        setSelectedAppointment(null);
        fetchAppointments();
        setOpenConfirmDeleteModal(false);  // Close modal on successful deletion
      } catch (error) {
        setSnackbar({ open: true, message: "Failed to delete appointment.", severity: "error" });
        console.error("Error deleting appointment:", error);
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDeleteModal(false);
  };


  const handleRequestAppointment = () => {
    setOpenAddEditModal(true);
  };

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
  
    // Check if selected time is between 9 AM and 4 PM
    const timeParts = selectedTime.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    if (hours >= 9 && hours <= 16 && minutes === 0) {
      setAppointmentTime(selectedTime);
    } else {
      // Update snackbar state with appropriate message
      setSnackbar({
        open: true,
        message: "Please select a time between 9 AM and 4 PM.",
        severity: "warning",
      });
    }
  };

  const submitAppointmentRequest = async (event) => {
    event.preventDefault();
    const requestData = {
      name: user.name,
      email: user.email,
      user_id: user.id,
      phone: user.phone,
      appointment_date: appointmentDate,
      appointment_time: appointmentTime,
    };

    setIsSubmitting(true);
    try {
      if (selectedAppointment) {
        await axios.put(`https://api.cope.ke/appointments/${selectedAppointment.appointment_id}`, requestData);
        setSnackbar({ open: true, message: "Appointment rescheduled successfully!", severity: "success" });
      } else {
        await axios.post("https://api.cope.ke/book_appointment", requestData);
        setSnackbar({ open: true, message: "Appointment requested successfully!", severity: "success" });
      }
      fetchAppointments();
      setOpenAddEditModal(false);
    } catch (error) {
      setSnackbar({ open: true, message: `Failed to ${selectedAppointment ? "reschedule" : "request"} appointment.`, severity: "error" });
      console.error(`Error ${selectedAppointment ? "rescheduling" : "requesting"} appointment:`, error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDetails = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleDeleteAppointment = async () => {
    if (selectedAppointment) {
      try {
        await axios.delete(`https://api.cope.ke/manage_appoitments/${selectedAppointment.appointment_id}`);
        setSnackbar({ open: true, message: "Appointment deleted successfully!", severity: "success" });
        setSelectedAppointment(null);
        fetchAppointments();
      } catch (error) {
        setSnackbar({ open: true, message: "Failed to delete appointment.", severity: "error" });
      }
    }
  };

  const handleRescheduleAppointment = () => {
    setOpenAddEditModal(true);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments.length > 0 ? (
            appointments.map((appointment) => (
              <TableRow key={appointment.appointment_id} onClick={() => handleOpenDetails(appointment)}>
                <TableCell>{formatDate(appointment.appointment_date)}</TableCell>
                <TableCell>{appointment.company_name}</TableCell>
                <TableCell>
                  <Chip label={appointment.status} color="primary" variant="outlined" />
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDetails(appointment);
                    }}
                    sx={{ color: "#00695C" }}
                  >
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography color="error">No appointments found</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => {
    if (!selectedAppointment) return null;
  
    try {
      // Combine `appointment_date` and `appointment_time`
      const appointmentDateTime = parse(
        `${format(new Date(selectedAppointment.appointment_date), "yyyy-MM-dd")} ${selectedAppointment.appointment_time}`,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );
  
      // Validate the parsed datetime
      if (!isValid(appointmentDateTime)) {
        console.error("Invalid datetime:", selectedAppointment);
        return (
          <Typography color="error">Invalid appointment details. Please check the date and time.</Typography>
        );
      }
  
      // Check if the appointment is in the past
      const isPastEvent = isBefore(appointmentDateTime, new Date());
  
      return (
        <Card>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              <IconButton onClick={() => setSelectedAppointment(null)} sx={{ color: "#00695C" }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography color="#00695C" variant="h6" sx={{ ml: 1, fontWeight: "bold" }}>
                Appointment Details
              </Typography>
            </Box>
            <Typography color="#666" variant="body1">
              Your therapy appointment is currently <strong>{selectedAppointment.status}</strong>. It is scheduled on{" "}
              <strong>{format(appointmentDateTime, "PPPP")}</strong> at{" "}
              <strong>{format(appointmentDateTime, "p")}</strong> with{" "}
              <strong>{selectedAppointment.company_name}</strong>. Kindly ensure you attend the therapy session. Further
              details shall be provided.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2} gap={2}>
              <Tooltip title="Delete Appointment">
                <IconButton onClick={handleOpenDeleteConfirmation} sx={{ color: "red" }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {!isPastEvent && (
                <Tooltip title="Reschedule Appointment">
                  <IconButton onClick={() => handleRescheduleAppointment(selectedAppointment)} sx={{ color: "orange" }}>
                    <RescheduleIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </CardContent>
        </Card>
      );
    } catch (error) {
      console.error("Error rendering appointment details:", error);
      return <Typography color="error">An error occurred while displaying the appointment details.</Typography>;
    }
  };
  


  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>Your Appointments</Typography>

      {!selectedAppointment && (
        <Toolbar>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search appointments"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleRequestAppointment}
            sx={{ marginLeft: 2 }}
          >
            Request
          </Button>
        </Toolbar>
      )}

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAppointment ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openAddEditModal} onClose={() => setOpenAddEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={submitAppointmentRequest} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom sx={{ color: "teal" }}>Set Appointment</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentDate"
            label="Appointment Date"
            name="appointmentDate"
            type="date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
            InputProps={{ inputProps: { min: format(addDays(new Date(), 1), "yyyy-MM-dd") }}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentTime"
            label="Appointment Time"
            name="appointmentTime"
            type="time"
            value={appointmentTime}
            onChange={handleTimeChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      {/* Confirm Delete Modal */}
      <Modal open={openConfirmDeleteModal} onClose={handleCancelDelete} sx={{ overflowY: "auto" }}>
        <Box sx={{ ...modalStyle, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>Confirm Deletion</Typography>
          <Typography variant="body2" gutterBottom>
            Are you sure you want to delete this appointment?
          </Typography>
          <Box display="flex" justifyContent="center" gap={2} mt={2}>
            <Tooltip title="Yes">
              <IconButton onClick={handleConfirmDelete} sx={{ color: "green" }}>
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="No">
              <IconButton onClick={handleCancelDelete} sx={{ color: "red" }}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  padding: "20px",
};

export default UserAppointment;
