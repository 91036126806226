import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  Container,
  Grid,
  Divider,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Badge,
  useTheme,
  Card
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import AdminMenu from "./AdminMenu";
import SuperuserBilling from "./SuperuserBilling";
import SuperuserCalendars from "./SuperuserCalendars";
import { UserContext } from "../UserContext";
import axios from "axios";
import logo from '../assets/secondary_logo.png'; // Replace with your actual logo image path


const drawerWidth = 240;
const closedDrawerWidth = 0;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: closedDrawerWidth,
    width: `calc(100% - ${closedDrawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
}));

const ViewSuperuserCalendars = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    axios.post('/logout')
      .then(() => {
        navigate('/login');
      })
      .catch(error => {
        console.error('Logout failed:', error);
      });
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleClientsViewClick = () => {
    navigate('/clients');
};

  const handleBillingViewClick = () => {
    navigate('/billing');
};

const handleDashboardViewClick = () => {
    navigate('/admin-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/questionaire');
};

const handlePostRecoveryViewClick = () => {
  navigate('/post-recovery');
};

const handleSubscriptionsViewClick = () => {
  navigate('/subscriptions');
};

const handleProfileViewClick = () => {
  navigate('/settings');
};

const handleAppointmentsViewClick = () => {
    navigate('/appointments');
  };

  const handleAssessmentsViewClick = () => {
    navigate('/assessments');
  }; 


  const handleTreatmentsViewClick = () => {
    navigate('/treatment');
  };

  const handleEComViewClick = () => {
    navigate('/ecom');
  };

  const handleTicketsViewClick = () => {
    navigate('/tickets');
  };

  const handleCalendarViewClick = () => {
    navigate('/calendar');
  };

  const handleAccountsViewClick = () => {
    navigate('/user-accounts');
  };

  const handleBlogpostsViewClick = () => {
    navigate('/blogposts');
  };

  const handleSessionsViewClick = () => {
    navigate('/sessions');
  };

  
  return (
      <Box sx={{bgcolor:"#F5F5F5", color:'#2C1C18'}}>
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            {/* Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
            </Box>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <FontAwesomeIcon icon={faBell} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={open}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>
          </Toolbar>
          <Divider />
          <AdminMenu
            handleMenuClick={handleMenuClick}
            handleLogout={handleLogout}
            handleClientsViewClick={handleClientsViewClick}
            handleBillingViewClick={handleBillingViewClick}
            handleDashboardViewClick={handleDashboardViewClick}
            handleQuestionaireViewClick={handleQuestionaireViewClick}
            handlePostRecoveryViewClick={handlePostRecoveryViewClick}
            handleSubscriptionsViewClick={handleSubscriptionsViewClick}
            handleProfileViewClick={handleProfileViewClick}
            handleAppointmentsViewClick={handleAppointmentsViewClick}
            handleAssessmentsViewClick={handleAssessmentsViewClick}
            handleTreatmentsViewClick={handleTreatmentsViewClick}
            handleEComViewClick={handleEComViewClick}
            handleTicketsViewClick={handleTicketsViewClick}
            handleCalendarViewClick={handleCalendarViewClick}
            handleAccountsViewClick={handleAccountsViewClick}
            handleBlogpostsViewClick={handleBlogpostsViewClick}          
            handleSessionsViewClick={handleSessionsViewClick}
          />
        </Drawer>
        
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <SuperuserCalendars />
              </Paper>
            </Grid>
          </Grid>
          <Typography style={{ color: "#000", paddingTop: 10 }}>
            All Rights Reserved © {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
      </Box>
  );
};

export default ViewSuperuserCalendars;
