import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';

const ServiceProviderPostRecovery = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [postRecovery, setPostRecovery] = useState([]);
  const [filteredPostRecovery, setFilteredPostRecovery] = useState([]);
  const [selectedRecovery, setSelectedRecovery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    date: '',
    name: '',
    status: '',
    recoveryDetails: ''
  });

  useEffect(() => {
    fetchPostRecovery();
  }, []);

  const fetchPostRecovery = async () => {
    try {
      const response = await axios.get('https://api.cope.ke/service_provider_post_recovery');
      setPostRecovery(response.data);
      setFilteredPostRecovery(response.data);
      setLoading(false);
    } catch (error) {
      handleError('Failed to fetch post recovery sessions');
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredPostRecovery(
      postRecovery.filter(
        (recovery) =>
          recovery.name.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (recovery) => {
    setSelectedRecovery(recovery);
    setFormData(recovery);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedRecovery(null);
    setFormData({
      date: '',
      name: '',
      status: '',
      recoveryDetails: ''
    });
  };

  const handleOpenAddEditModal = (recovery = null) => {
    setSelectedRecovery(recovery);
    setFormData(recovery || {
      date: '',
      name: '',
      status: '',
      recoveryDetails: ''
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedRecovery(null);
    setFormData({
      date: '',
      name: '',
      status: '',
      recoveryDetails: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedRecovery) {
        await axios.put(`/post-recovery/${selectedRecovery.id}`, formData);
      } else {
        await axios.post('/post-recovery', formData);
      }
      fetchPostRecovery();
      handleCloseAddEditModal();
    } catch (error) {
      handleError('Failed to save recovery session');
    }
  };

  const handleDelete = async (recoveryId) => {
    try {
      await axios.delete(`/post-recovery/${recoveryId}`);
      fetchPostRecovery();
    } catch (error) {
      handleError('Failed to delete recovery session');
    }
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setOpenErrorModal(true);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPostRecovery.length > 0 ? (
            filteredPostRecovery.map((recovery) => (
              <TableRow key={recovery.id} onClick={() => handleOpenDetailsModal(recovery)}>
                <TableCell>{new Date(recovery.date).toLocaleString()}</TableCell>
                <TableCell>{recovery.name}</TableCell>
                <TableCell>{recovery.status}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(recovery); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(recovery.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No recovery sessions available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Recovery Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="datetime-local"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="status"
          label="Status"
          name="status"
          value={formData.status}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="recoveryDetails"
          label="Recovery Details"
          name="recoveryDetails"
          value={formData.recoveryDetails}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color='#00695C' variant="h4" gutterBottom>Post Recovery</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search recovery sessions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddEditModal()}
        >
          Add Recovery
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable()
      )}

      <Modal
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        sx={{ overflowY: 'auto' }}
      >
        <Box sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>

      <Modal
        open={openAddEditModal}
        onClose={handleCloseAddEditModal}
        sx={{ overflowY: 'auto' }}
      >
        <Box sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            {selectedRecovery ? 'Edit Recovery' : 'Add Recovery'}
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="date"
              label="Date"
              name="date"
              type="datetime-local"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="status"
              label="Status"
              name="status"
              value={formData.status}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="recoveryDetails"
              label="Recovery Details"
              name="recoveryDetails"
              value={formData.recoveryDetails}
              onChange={handleChange}
              multiline
              rows={4}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 1 }}
              onClick={handleCloseAddEditModal}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorModal(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderPostRecovery;
