import React, { useState, useEffect, useContext } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Badge, Divider } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../UserContext';

const UserMenu = ({
  handleDashboardViewClick,
  handleAppointmentsViewClick,
  handleSessionsViewClick,
  handleAssessmentsViewClick,
  handleBillingViewClick,
  handleProfileViewClick,
}) => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  const [openTherapy, setOpenTherapy] = useState(false);
  const [openAccounts, setOpenAccounts] = useState(false);

  const [appointmentsStatus, setAppointmentsStatus] = useState(0);
  const [sessionsStatus, setSessionsStatus] = useState(0);
  const [assessmentsStatus, setAssessmentsStatus] = useState(0);

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const appointmentsResponse = await axios.get('https://cope.ke/appointments?status=pending');
        const sessionsResponse = await axios.get('https://cope.ke/sessions?status=completed');
        const assessmentsResponse = await axios.get('https://cope.ke/assessments?status=pending');

        setAppointmentsStatus(appointmentsResponse.data.count || 0);
        setSessionsStatus(sessionsResponse.data.count || 0);
        setAssessmentsStatus(assessmentsResponse.data.count || 0);
      } catch (error) {
        console.error('Error fetching status data:', error);
      }
    };

    fetchStatuses();
  }, []);

  const handleTherapyClick = () => setOpenTherapy(!openTherapy);
  const handleAccountsClick = () => setOpenAccounts(!openAccounts);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <List component="nav" aria-label="user menu">
      {/* Dashboard */}
      <ListItem button onClick={handleDashboardViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <EventIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Your Dashboard" />
      </ListItem>

      {/* Therapy Dropdown */}
      <ListItem button onClick={handleTherapyClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <LocalHospitalIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Therapy" />
        {openTherapy ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openTherapy} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleAppointmentsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={appointmentsStatus} color="secondary">
                <EventIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Appointments" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleSessionsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={sessionsStatus} color="secondary">
                <GroupIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Sessions" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleAssessmentsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={assessmentsStatus} color="secondary">
                <AssignmentIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Assessments" />
          </ListItem>
        </List>
      </Collapse>

      {/* Billing */}
      <ListItem button onClick={handleBillingViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Billing" />
      </ListItem>

      {/* User Accounts Dropdown */}
      <ListItem button onClick={handleAccountsClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="User Accounts" />
        {openAccounts ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleProfileViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Your Profile" />
          </ListItem>
        </List>
      </Collapse>

      <Divider />

      {/* Logout */}
      <ListItem button onClick={handleLogout} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Sign Out" />
      </ListItem>
    </List>
  );
};

export default UserMenu;
