import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Modal,
  Button,
  Breadcrumbs,
  useTheme,
  Box,
  Link as MuiLink,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

import ScrollTopFab from "../components/ScrollTopFab";

import ContactUs from "../components/ContactUs";


const navbarHeight = 64;

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  marginTop: `${navbarHeight}px`,
  justifyContent: "center",
  alignItems: "center",
  height: "300px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const textStyle = {
  zIndex: 2,
  color: "white",
  fontSize: "48px",
  position: "relative",
};

const Terms = () => {
  const theme = useTheme();

  const location = useLocation();

  const navigate = useNavigate();
  const [showContacts, setShowContacts] = useState(false)

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleContacts = () =>  navigate('/contacts');

  

  const [activePage, setPage] = useState('Term');

  

  useEffect(() => {
    const currentPath = location.pathname.slice(1) || 'Terms';
    setPage(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    window.scrollTo(0, 0); // Scroll to top
    document.title = "Terms of Use | Cope Wellness"; // Update page title
  }, [location]);

  if (showContacts) return <ContactUs />;

  return (
    <div>
      <Navbar setPage={setPage} />
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "200px",
          bgcolor: "primary.main",
          color: "white",
          mb: 4,
        }}
      >
        <Typography variant="h3">Terms of Use</Typography>
        <Breadcrumbs
          sx={{
            position: "absolute",
            bottom: 20,
            left: 20,
            color: "white",
          }}
        >
          <MuiLink color="#f5f5f5" onClick={() => navigate('/')} sx={{cursor:'pointer'}}>
            Home
          </MuiLink>
          <Typography color="#fff">Terms of Use</Typography>
        </Breadcrumbs>
      </Box>
      <Paper
        sx={{
          padding: 2,
          color: "#000",
          margin: "20px auto",
          maxWidth: "800px",
          boxShadow: "1px 1px 10px 0px grey",
          transition: "background-color 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "1px 1px 15px 0px teal",
          },
        }}
      >

        <Typography color="#00695C" textAlign={'center'} variant="h4" gutterBottom>
          Cope Wellness Online Therapy Services
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Welcome to our cloud-based therapy platform. By accessing and utilizing our services, you agree to comply with and be bound by the following terms and conditions.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          1. Account Registration
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Users must provide their name and email address for account registration, activation, and password setup. All information provided will be securely stored.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          2. Assessment and Therapy Services
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Upon submission of the initial questionnaire, a licensed therapist will conduct an assessment. If therapy services are required, an appointment email will be sent to confirm the date and time. Users will be prompted for payment. For corporate users, the parent company will be billed per session.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          3. Course of Treatment
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Assessments will determine the appropriate course of treatment. Users will receive regular email notifications for reminders, billing, newsletters, and other relevant communications.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          4. Post-Treatment
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          When no further sessions or treatments are needed and the patient is deemed recovered, the details will be moved to post-recovery, and a congratulatory message will be sent to the user.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          5. Marketing and Awareness
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          We utilize content, marketing materials, and blog posts to raise awareness. Users may share their experiences on social media.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          6. Contact Information
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          Contact details for therapy services and resources across the country will be available on the platform, with a map displaying locations for booking appointments.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          7. Security
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          All information provided by users is securely stored in compliance with data protection regulations.
        </Typography>
        <Typography color="#00695C" variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography color="#666" variant="body1" paragraph>
          If you have any questions about this terms of use, please contact us.
        </Typography>
        <Button onClick={handleContacts} variant="contained" sx={{ backgroundColor: '#1a6051', color: '#fff', padding: '10px 20px' }}>
              Contact Us
        </Button>
        <ScrollTopFab />
      </Paper>
        <ScrollTopFab />
    </div>
  );
};

export default Terms;
