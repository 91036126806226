// src/components/SupportLinks.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const SupportLinks = () => {
  return (
    <Box sx={{ position: 'fixed', right: 16, top: 100 }}>
      <Typography variant="h6" gutterBottom>
        Support
      </Typography>
      <Link href="#" underline="hover" display="block">
        Emergency Support Services
      </Link>
      <Link href="#" underline="hover" display="block">
        Helpline for Mental Health
      </Link>
      <Link href="#" underline="hover" display="block">
        Customer Support Line
      </Link>
    </Box>
  );
};

export default SupportLinks;