import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "../components/IconMarquee";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import FlipAnimation from "../components/FlipAnimation";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";

const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "Partner With Us",
    content:
      "Join us in our mission to transform lives through better health and wellness.",
    subContent: "We invite you to join us in making a difference.",
    image:
      "https://images.unsplash.com/photo-1600091474842-83bb9c05a723?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    action: "Back",
    actionLink: "/",
    cardLink: "/",
  },
  {
    title: "Join Our Journey",
    content:
      "Your journey towards making a lasting impact in the health industry starts here.",
    subContent:
      "We believe in the power of collaboration and community in making a difference. ",
    image: "https://source.unsplash.com/random?business",
  },
  {
    title: "Grow With Us",
    content: "Your partnership is a step towards a brighter, healthier future for all.",
    subContent:
      "Support our events and initiatives on the ground, providing invaluable hands-on help. ",
    image: "https://source.unsplash.com/random?technology",
  },
  // Add more card data as needed
];

const Partner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <div style={{ maxHeight: "100vh" }}>
      <Navbar />
      <Paper
        style={{
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} justifyContent="space-between">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card sx={{ backgroundColor: "#b2dfdb" }}>
                    <CardMedia
                      sx={{ height: 100 }}
                      image={card.image}
                      title={card.title}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#4caf50",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {card.content}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                        }}
                      >
                        {card.subContent}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        sx={{ color: "#8d6e63" }}
                        size="small"
                        href={card.actionLink}
                      >
                        {card.action}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              style={{ marginTop: 20, textAlign: "center", color: "#00e5ff" }}
            >
              What the Experts Say
            </Typography>
            <Slider {...settings} style={{ margin: "20px" }}>
              {testimonials.map((testimonial, index) => (
                <div key={index} style={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontStyle: "italic",
                      textAlign: "center",
                      color: "#1de9b6",
                    }}
                  >
                    "{testimonial.quote}"
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginTop: "10px", textAlign: "center" }}
                  >
                    - {testimonial.author}, {testimonial.position} at{" "}
                    {testimonial.company}
                  </Typography>
                </div>
              ))}
            </Slider>
            <Stack>
              <Typography
                color="#00e5ff"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                How are you feeling today?
              </Typography>
              <Button
                variant="contained"
                style={{
                  alignSelf: "center",
                  backgroundColor: "#4caf50",
                }}
              >
                Find Out
              </Button>
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <SignIn
                  
                />
              </Modal>
            </Stack>
          </Grid>
        </Grid>

        <IconMarquee />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography color="#00e5ff" style={{ textAlign: "center" }}>
              Are you a Healthcare service provider?
            </Typography>
            <Button
              variant="contained"
              style={{
                margin: "auto",
                display: "block",
                backgroundColor: "#4caf50",
              }}
              onClick={handleOpen}
            >
              Get Started
            </Button>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <SignIn  />
            </Modal>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Partner;
