import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import HomePage from './HomePage/HomePage';
import ContactUs from '../components/ContactUs';
import Community from './Community';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import Corporates from './Corporates';

const Homepage = () => {
  const [activePage, setPage] = useState('Home');
  const navigate = useNavigate();
  const location = useLocation();

  // Update active page based on route and scroll to top on page change
  useEffect(() => {
    const currentPath = location.pathname.slice(1) || 'Home';
    setPage(currentPath.charAt(0).toUpperCase() + currentPath.slice(1));
    window.scrollTo(0, 0); // Scroll to the top on page change
  }, [location]);

  // Render SEO metadata dynamically
  const renderHelmet = () => {
    let title, description;
    switch (activePage) {
      case 'Blog':
        title = 'Blog - Mental Health Insights';
        description = 'Latest mental health insights and articles.';
        break;
      case 'Corporates':
        title = 'Corporates - Cope Wellness';
        description = 'Learn about our corporate wellness program.';
        break;
      case 'Contacts':
        title = 'Contact Us - Cope Wellness';
        description = 'Get in touch with our support team.';
        break;
      case 'Terms':
        title = 'Terms and Conditions - Cope Wellness';
        description = 'Our terms and conditions for using Cope services.';
        break;
      case 'Privacy-Policy':
        title = 'Privacy Policy - Cope Wellness';
        description = 'Learn about our privacy policies.';
        break;
      default:
        title = 'Your Mental Wellness Journey Starts Here - Cope Wellness';
        description = 'Cope is an online platform providing mental health services.';
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  };

  // Render breadcrumbs dynamically
  const renderBreadcrumbs = () => (
    activePage !== 'Home' && (
      <Box sx={{ marginTop: '20px' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: 'gray' }} />} aria-label="breadcrumb">
          <Link color="#000" onClick={() => navigate('/')} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            Home
          </Link>
          <Typography color="teal">{activePage}</Typography>
        </Breadcrumbs>
      </Box>
    )
  );

  return (
    <HelmetProvider>
      {renderHelmet()}
      <div>
        {renderBreadcrumbs()}
        {activePage === 'Home' && <HomePage />}
        {activePage === 'Blog' && <Community />}
        {activePage === 'Corporates' && <Corporates />}
        {activePage === 'Contacts' && <ContactUs />}
        {activePage === 'Terms' && <Terms />}
        {activePage === 'Privacy-Policy' && <PrivacyPolicy />}
      </div>
      <Footer setPage={setPage} />
    </HelmetProvider>
  );
};

export default Homepage;
