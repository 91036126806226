import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
  Chip,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { UserContext } from "../UserContext";  // Assuming UserContext exists to get provider_id
import axios from "axios";

const ServiceProviderQuestionnaire = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useContext(UserContext); // Get the provider_id from UserContext

  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false); // Modal to add new questionnaire
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/sp-questions/${user.provider_id}`);
      const questions = response.data
      setQuestions([questions]);
      setFilteredQuestions([questions]);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch questions");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredQuestions(
      questions.filter(
        (question) =>
          question.question_text.toLowerCase().includes(value) ||
          question.options.join(", ").toLowerCase().includes(value)
      )
    );
  };

  const handleOpenEditModal = (question) => {
    setSelectedQuestion(question);
    setFormData(question);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setFormData({});
    setSelectedQuestion(null);
  };

  const handleOpenAddModal = () => {
    setFormData({ title: "", options: "" });
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setFormData({});
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.put(`https://api.cope.ke/sp-questions/${selectedQuestion.id}`, formData);
      setSuccess("Question updated successfully!");
      fetchQuestions();
      handleCloseEditModal();
    } catch (error) {
      setError("Failed to update question");
      setLoading(false);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Post new question with provider_id
      await axios.post("https://api.cope.ke/sp-questions", { ...formData, provider_id: user.provider_id });
      setSuccess("Question added successfully!");
      fetchQuestions();
      handleCloseAddModal();
    } catch (error) {
      setError("Failed to add question");
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://api.cope.ke/sp-questions/${id}`);
      setSuccess("Question deleted successfully!");
      fetchQuestions();
    } catch (error) {
      setError("Failed to delete question");
      setLoading(false);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="center">Options</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {filteredQuestions.map((question) => (
        <TableRow key={question.provider_id}>
          <TableCell>{question.question_text}</TableCell>
          <TableCell align="center">
            {/* Convert options string to an array by splitting on commas */}
            {question.options
              .split(',')  // Convert the comma-separated string back into an array
              .map((option, index) => (
                <Chip key={index} label={option.trim()} sx={{ marginRight: 0.5 }} />  // Trim spaces from each option
            ))}
          </TableCell>
          <TableCell align="right">
            <IconButton color="primary" onClick={() => handleOpenEditModal(question)} sx={{ color: "#00695C" }}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(question.provider_id)} sx={{ color: "#00695C" }}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}

        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>
        Your Questionnaire
      </Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search questions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          sx={{ marginLeft: 'auto', backgroundColor: "#00695C", color: "#fff" }}
          variant="contained"
        >
          Add Question
        </Button>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredQuestions.length > 0 ? (
        renderTable()
      ) : (
        <Typography color="red" variant="body1" sx={{ mt: 4 }}>
          No questions found.
        </Typography>
      )}

      {/* Modal for Adding New Question */}
      <Modal open={openAddModal} onClose={handleCloseAddModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? "90%" : 400 }}>
          <Typography variant="h6" gutterBottom>
            Add New Question
          </Typography>
          <Box component="form" onSubmit={handleAddSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Question"
              name="title"
              value={formData.title || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="options"
              label="Options (Comma-separated)"
              name="options"
              value={formData.options || ""}
              onChange={handleChange}
              helperText="Separate options with commas"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#00695C", color: "#fff" }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal for Editing Question */}
      <Modal open={openEditModal} onClose={handleCloseEditModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? "90%" : 400 }}>
          <Typography variant="h6" gutterBottom>
            Edit Question
          </Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="title"
              label="Question"
              name="title"
              value={formData.title || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="options"
              label="Options"
              name="options"
              value={formData.options || ""}
              onChange={handleChange}
              helperText="Comma-separated values"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#00695C", color: "#fff" }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Success and Error Handling */}
      {error && (
        <Modal open={Boolean(error)} onClose={() => setError("")}>
          <Box sx={modalStyle}>
            <Typography variant="h6" gutterBottom>
              Error
            </Typography>
            <Typography variant="body1">{error}</Typography>
            <Button onClick={() => setError("")} color="primary">
              OK
            </Button>
          </Box>
        </Modal>
      )}

      {success && (
        <Modal open={Boolean(success)} onClose={() => setSuccess("")}>
          <Box sx={modalStyle}>
            <Typography variant="h6" gutterBottom>
              Success
            </Typography>
            <Typography variant="body1">{success}</Typography>
            <Button onClick={() => setSuccess("")} color="primary">
              OK
            </Button>
          </Box>
        </Modal>
      )}
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default ServiceProviderQuestionnaire;
