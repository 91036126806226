import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Fab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  NoteAdd as NoteAddIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ExpandMore as ExpandMoreIcon,
  Event as EventIcon,
  ArrowBack as ArrowBackIcon,
  CalendarToday as CalendarTodayIcon,
  LocalHospital as LocalHospitalIcon,
  Info as InfoIcon,
  ShoppingCart as CartIcon,
  CheckCircle as CheckIcon,
  Send as SendIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

import { UserContext } from '../UserContext';

const SuperuserTherapyAssessments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { user } = useContext(UserContext);
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClientAssessments, setSelectedClientAssessments] = useState(null);
  const [selectedDateAssessments, setSelectedDateAssessments] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [notes, setNotes] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("09:00");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [tabIndex, setTabIndex] = useState(0);
  const [analysisResult, setAnalysisResult] = useState("");
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchAssessments();
    fetchTreatmentPlans();
    fetchProviders();
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/responses");
      const assessmentsWithDetails = await Promise.all(response.data.map(async (assessment) => {
        const userDetails = await fetchUserDetails(assessment.user_id);
        const provider = await fetchProviderDetails(7);
        const questionText = await fetchQuestionText(assessment.question_id);
        return { ...assessment, ...userDetails, provider, question_text: questionText };
      }));
      console.log(assessmentsWithDetails)
      setAssessments(assessmentsWithDetails);
      setFilteredAssessments(groupByClientId(assessmentsWithDetails));
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch assessments data", severity: "error" });
      setLoading(false);
    }
  };

  const fetchTreatmentPlans = async () => {
    try {
      const response = await axios.get('https://api.cope.ke/subscription-plan');
      setTreatmentPlans(response.data);
    } catch (error) {
      showSnackbar('Failed to fetch treatment plans', 'error');
    }
  };

   // Helper function to format the date
const formatAppointmentDate = (date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date(date));

  const day = new Date(date).getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return formattedDate.replace(day, `${day}${daySuffix(day)}`);
};

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://api.cope.ke/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch user details for user_id ${userId}`, error);
      return { name: "Unknown", email: "Unknown" };
    }
  };


  const fetchProviderDetails = async (providerId) => {
    try {
      const response = await axios.get(`https://api.cope.ke/service_providers/${providerId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch user details for user_id ${providerId}`, error);
      return { name: "Unknown", email: "Unknown" };
    }
  };

  const fetchQuestionText = async (questionId) => {
    try {
      const response = await axios.get(`https://api.cope.ke/questions/${questionId}`);
      return response.data.question_text;
    } catch (error) {
      console.error(`Failed to fetch question text for question_id ${questionId}`, error);
      return "Unknown Question";
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/service_providers");
      setProviders(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch providers data", severity: "error" });
    }
  };

  const groupByClientId = (data) => {
    const grouped = data.reduce((acc, assessment) => {
      const clientId = assessment.user_id;
      if (!acc[clientId]) {
        acc[clientId] = [];
      }
      acc[clientId].push(assessment);
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const groupByDate = (data) => {
    const grouped = data.reduce((acc, assessment) => {
      const date = new Date(assessment.submitted_at).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(assessment);
      return acc;
    }, {});
    return Object.entries(grouped);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (selectedClientAssessments) {
      setFilteredAssessments(
        assessments.filter(
          (assessment) =>
            assessment.user_id === selectedClientAssessments[0].user_id &&
            (assessment.answer.toLowerCase().includes(value) || assessment.question_text.toLowerCase().includes(value))
        )
      );
    } else {
      setFilteredAssessments(
        groupByClientId(
          assessments.filter(
            (assessment) =>
              assessment.answer.toLowerCase().includes(value) || assessment.question_text.toLowerCase().includes(value)
          )
        )
      );
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCancel = () => {
    setConfirmModalOpen(false);
  };

  const handleCardClick = (clientAssessments) => {
    setSelectedClientAssessments(clientAssessments);
  };

  const handleDateClick = (dateAssessments) => {
    setSelectedDateAssessments(dateAssessments);
  };

  const handleBackClick = () => {
    if (selectedDateAssessments) {
      setSelectedDateAssessments(null);
    } else {
      setSelectedClientAssessments(null);
      setFilteredAssessments(groupByClientId(assessments));
    }
  };

  const handleEditIconClick = (event, assessment) => {
    event.stopPropagation();
    setFormData(assessment);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (event, assessment) => {
    event.stopPropagation();
    setFormData(assessment);
    setOpenDeleteModal(true);
  };
  
  const handleConfirmSelection = () => {
    // Open confirmation modal
    setConfirmModalOpen(true);
  };

  const handleNotesIconClick = (event, assessment) => {
    event.stopPropagation();
    setFormData(assessment);
    setOpenNotesModal(true);
  };

  const handleAppointmentIconClick = (event, assessment) => {
    event.stopPropagation();
    setFormData(assessment);
    setAppointmentDate(new Date().toISOString().split('T')[0]);  // Set default date to current date
    setOpenAppointmentModal(true);
  };

  const handleReferralIconClick = (event, assessment) => {
    event.stopPropagation();
    setFormData(assessment);
    setOpenReferralModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      setIsSubmitting(false);
      return;
    }
    try {
      await axios.put(`https://api.cope.ke/assessments/${formData.response_id}`, formData);
      fetchAssessments();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Assessment updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update assessment data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/assessments/${formData.response_id}`);
      fetchAssessments();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Assessment deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete assessment data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleNotesSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`https://api.cope.ke/assessments/${formData.response_id}/notes`, { notes });
      fetchAssessments();
      setOpenNotesModal(false);
      setSnackbar({ open: true, message: "Notes added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add notes", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleSendToTherapy = async (assessment) => {
    setPlanModalOpen(true);
    setFormData(assessment);
    setSelectedAssessment(assessment);
  };

  const handleAppointmentSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/book_appointment", { 
        user_id: formData.user_id, 
        provider_id: selectedProvider, 
        appointment_date: `${appointmentDate} ${appointmentTime}`,
        email: formData.email  // Include the user email in the POST request
      });
      fetchAssessments();
      setOpenAppointmentModal(false);
      setSnackbar({ open: true, message: "Appointment booked successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to book appointment", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAnalysisSubmit = async () => {
    try {
      const response = await axios.post(`https://api.cope.ke/assessments/${formData.response_id}/analyze`);
      setAnalysisResult(response.data.analysis);
      setSnackbar({ open: true, message: "Analysis completed successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to complete analysis", severity: "error" });
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handlePlanSubmit = async () => {
    setActionLoading(true);
    setPlanModalOpen(false);

    const sessionData = {
        name: selectedAssessment.name,
        user_id: selectedAssessment.user_id,
        provider_id: 7,
        user_email: selectedAssessment.email,
        provider_email: user.email,
        phone: selectedAssessment.phone,
        plans: selectedPlans,
        price: totalAmount
    };
    console.log(selectedAssessment)
    console.log(sessionData)

    try {
        const therapyResponse = await axios.post('https://api.cope.ke/therapy_sessions', sessionData);
        
        // If invoice generated, download the PDF file
        if (therapyResponse.data.invoice_file) {
            const link = document.createElement('a');
            link.href = `https://api.cope.ke/invoices/${therapyResponse.data.invoice_file}`;
            link.download = therapyResponse.data.invoice_file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        showSnackbar('Therapy session scheduled and invoice generated!', 'success');
        setConfirmModalOpen(false);  // Close confirmation modal only on success
    } catch (error) {
        showSnackbar('Failed to schedule therapy or generate invoice', 'error');
    } finally {
        setActionLoading(false);
    }
};

  const renderAssessmentDetails = (dateAssessments) => (
    dateAssessments.map((assessment) => (
      <Accordion key={assessment.response_id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{assessment.question_text}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Answer: {assessment.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))
  );

  const renderDateGroupedIcons = () => {
    const groupedByDate = groupByDate(selectedClientAssessments);
    return groupedByDate.map(([date, assessments]) => (
      <Box key={date} sx={{ mb: 4, textAlign: 'center' }}>
        <Tooltip title={`View assessments for ${date}`}>
          <IconButton 
            onClick={() => handleDateClick(assessments)} 
            sx={{ 
              fontSize: 40, 
              transition: "transform 0.3s", 
              "&:hover": { transform: "scale(1.2)", color: "blue" } 
            }}
          >
            <CalendarTodayIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Typography variant="body1">{date}</Typography>
      </Box>
    ));
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredAssessments.map((clientAssessments) => (
        <Card key={clientAssessments[0].user_id} sx={{ width: 300, m: 2, cursor: "pointer", transition: "transform 0.3s", "&:hover": { transform: "scale(1.05)" } }} onClick={() => handleCardClick(clientAssessments)}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar>
                <FontAwesomeIcon icon={faUser} size="2x" />
              </Avatar>
              <Box>
                <Tooltip title="Add Notes">
                  <IconButton onClick={(e) => handleNotesIconClick(e, clientAssessments[0])} sx={{ color: "teal" }}>
                    <NoteAddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send to Therapy">
                  
                  <IconButton onClick={() => handleSendToTherapy(clientAssessments[0])} sx={{ color: "teal" }}>
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography sx={{color:"#00695C" }} variant="h6">{clientAssessments[0].name || "Client"}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {clientAssessments[0].email || "Email"}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {clientAssessments[0].status === "inactive" ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  const handlePlanCartClick = (planId, price) => {
    setSelectedPlans((prevSelectedPlans) => {
      const updatedPlans = prevSelectedPlans.includes(planId)
        ? prevSelectedPlans.filter((id) => id !== planId) // Deselect plan
        : [...prevSelectedPlans, planId]; // Select plan

      setTotalAmount((prevTotal) => {
        return prevSelectedPlans.includes(planId)
          ? prevTotal - price // Subtract price when deselected
          : prevTotal + price; // Add price when selected
      });

      return updatedPlans;
    });
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{color:"#00695C" }} gutterBottom>Therapy Assessments</Typography>
        <Tooltip title="How to use">
          <IconButton onClick={() => setOpenInfoModal(true)}>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Back">
          <IconButton onClick={handleBackClick} disabled={!selectedClientAssessments && !selectedDateAssessments}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search assessments"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ flexGrow: 1, mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedDateAssessments ? (
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary" >
            <Tab label="Questions & Responses" sx={{ color: tabIndex === 1 ? '#00695C' : '#F9A64F' }} />
            <Tab label="Analysis" sx={{ color: tabIndex === 1 ? '#00695C' : '#F9A64F' }} />
            <Tab label="Notes" sx={{ color: tabIndex === 1 ? '#00695C' : '#F9A64F' }} />
          </Tabs>
          {tabIndex === 0 && (
            <Box>
              {renderAssessmentDetails(selectedDateAssessments)}
            </Box>
          )}
          {tabIndex === 1 && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAnalysisSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Request Analysis"}
              </Button>
              {analysisResult && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">Analysis Result</Typography>
                  <Typography variant="body1">{analysisResult}</Typography>
                </Box>
              )}
            </Box>
          )}
          {tabIndex  === 2 && (
            <Box sx={{ mt: 2, color:'red' }}>
              <Typography variant="h6">Notes</Typography>
              <Typography variant="body1">{notes}</Typography>
            </Box>
          )}
        </Box>
      ) : selectedClientAssessments ? (
        <Box>
          {renderDateGroupedIcons()}
        </Box>
      ) : filteredAssessments.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No assessments data found.
        </Typography>
      )}

      {/* Treatment Plan Modal */}
      <Dialog open={planModalOpen} onClose={() => setPlanModalOpen(false)}>
        <DialogTitle sx={{color:'#00695C'}} >Select Treatment Plan</DialogTitle>
        <DialogContent>
          {/* Native HTML date-time picker */}
          
          <Box mt={2}>
            <Typography sx={{color:'#00695C'}} variant="h6">Total Amount: Ksh {totalAmount.toFixed(2)}</Typography> {/* Ensure two decimal points */}
          </Box>
          
          {treatmentPlans.map((plan) => (
            <Box
            key={plan.plan_id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#f0f0f0' }
            }}
          >
            <Checkbox
              sx={{color:'#2C1C18'}}
              checked={selectedPlans.includes(plan.plan_id)}
              onChange={() => handlePlanCartClick(plan.plan_id, Number(plan.price))}
            />
            <Typography variant="body1">
              {plan.plan_type} - Ksh {Number(plan.price).toFixed(2)}
            </Typography>
            <Tooltip title="Add to Cart">
              <IconButton 
                onClick={() => handlePlanCartClick(plan.plan_id, Number(plan.price))} 
                sx={{ color: '#00695C', '&:hover': { transform: 'scale(1.1)' } }}
              >
                <CartIcon />
              </IconButton>
            </Tooltip>
          </Box>    
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirmSelection} disabled={!selectedPlans.length}>
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Modal */}
      <Dialog open={confirmModalOpen} onClose={handleCancel}>
        <DialogTitle sx={{color: '#00695C'}}>Confirm Appointment</DialogTitle>
        <DialogContent>
          <Typography>
            You have set the appointment for  for the selected plans. The client will be billed Ksh {totalAmount.toFixed(2)}.
          </Typography>
        </DialogContent>
        <DialogActions>
        <IconButton onClick={handleCancel} sx={{ color: 'red' }}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={handlePlanSubmit} sx={{ color: 'green' }}>
          {actionLoading ? <CircularProgress size={24} /> : <CheckIcon />}
        </IconButton>
      </DialogActions>
      </Dialog>

      

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Assessment</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="status"
            label="Status"
            name="status"
            value={formData.status || ""}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openNotesModal} onClose={() => setOpenNotesModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleNotesSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Notes</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="Date"
            name="date"
            type="date"
            value={formData.date || new Date().toISOString().split('T')[0]}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={formData.notes || ""}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAppointmentModal} onClose={() => setOpenAppointmentModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAppointmentSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Book Appointment</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentDate"
            label="Appointment Date"
            name="appointmentDate"
            type="date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentTime"
            label="Appointment Time"
            name="appointmentTime"
            type="time"
            value={appointmentTime}
            onChange={(e) => setAppointmentTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="provider-select-label">Service Provider</InputLabel>
            <Select
              labelId="provider-select-label"
              id="provider-select"
              value={selectedProvider}
              label="Service Provider"
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              {providers.map((provider) => (
                <MenuItem key={provider.provider_id} value={provider.provider_id}>
                  {provider.company_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this assessment record?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" color="#00695C" gutterBottom>How to Use Therapy Assessment Screen</Typography>
          <Typography variant="body1" gutterBottom>
            <ul>
              <li>Click on a client card to view assessments grouped by date.</li>
              <li>Click on a calendar icon to view questions and responses for that date.</li>
              <li>Use the tabs to switch between viewing questions/responses and analysis.</li>
              <li>Use the icons on the client card to edit, delete, add notes, book an appointment, or refer a client.</li>
              <li>Click on the information icon to view this help modal.</li>
            </ul>
          </Typography>
          <Button onClick={() => setOpenInfoModal(false)} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Tooltip title="Return to Top">
        <Fab
          color="primary"
          onClick={handleScrollToTop}
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserTherapyAssessments;
