import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Drawer as MuiDrawer,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Badge,
  Snackbar,
  Alert,
  Divider,
  Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBell,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ServiceProviderMenu from "./ServiceProviderMenu";
import ServiceProviderTherapyAssessments from "./ServiceProviderTherapyAssessments";
import TicketModal from "./TicketModal";

import { UserContext } from "../UserContext"; // Import UserContext

import logo from '../assets/logo.png';

const drawerWidth = 240;
const closedDrawerWidth = 0;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: closedDrawerWidth,
    width: `calc(100% - ${closedDrawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
}));

const ViewServiceProviderAssessments = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext); // Access user from context
  const [open, setOpen] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [openModal, setOpenModal] = useState(false);
  const [badgeCounts, setBadgeCounts] = useState({
    inbox: 0,
    consultations: 0,
    observations: 0,
    referrals: 0,
    therapy: 0,
  });

  useEffect(() => {
    fetchBadgeCounts();
  }, []);

  const fetchBadgeCounts = async () => {
    try {
      // Replace the URL and logic as needed to fetch the badge counts
      const [inbox, consultations, observations, referrals, therapy] = await Promise.all([
        axios.get("https://api.cope.ke/appointments?status=pending"),
        axios.get("https://api.cope.ke/assessments?status=pending"),
        axios.get("https://api.cope.ke/sessions?status=pending"),
        axios.get("https://api.cope.ke/treatment?status=pending"),
        axios.get("https://api.cope.ke/post_recoveries?status=active"),
      ]);

      setBadgeCounts({
        inbox: inbox.data.length,
        consultations: consultations.data.length,
        observations: observations.data.length,
        referrals: referrals.data.length,
        therapy: therapy.data.length,
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to fetch badge counts",
        severity: "error",
      });
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: "", severity: "success" });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: "24px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          {/* Display the logged-in user's name */}
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Welcome, {user ? user.company_name : 'Guest'}
          </Typography>
          
          <IconButton color="inherit">
            <Badge badgeContent={badgeCounts.inbox} color="secondary">
              <FontAwesomeIcon icon={faBell} />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={open}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          {/* Logo */}
          <Box sx={{ display: 'flex', justifyContent:'center',alignItems: 'center' }}>
            <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
          </Box>
          <IconButton onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
        </Toolbar>
        <Divider />
        <ServiceProviderMenu  />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <ServiceProviderTherapyAssessments />
              </Paper>
            </Grid>
          </Grid>
          <Typography style={{ color: "#000", paddingTop: 10 }}>
            All Rights Reserved © {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ViewServiceProviderAssessments;
