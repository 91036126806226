import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as ViewIcon,
} from "@mui/icons-material";
import axios from "axios";

const SuperuserSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(""); // For handling dynamic content in modal (treatment, merchandise, etc.)
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    duration: "",
    recurrence: "",
    type: "", // e.g., Treatment Plan, Merchandise, Corporate Wellness
    deliveryDetails: "", // Specific to Merchandise
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/subscriptions");
      setSubscriptions(response.data);
      setFilteredSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch subscriptions data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSubscriptions(
      subscriptions.filter(
        (subscription) =>
          subscription.name.toLowerCase().includes(value) ||
          subscription.description.toLowerCase().includes(value)
      )
    );
  };

  const handleAddClick = (type) => {
    setFormData({
      name: "",
      description: "",
      price: "",
      duration: "",
      recurrence: "",
      type,
      deliveryDetails: "",
    });
    setModalType(type);
    setOpenModal(true);
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/subscriptions", formData);
      fetchSubscriptions();
      setOpenModal(false);
      setSnackbar({ open: true, message: "Product offering added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add product offering", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredSubscriptions.map((subscription) => (
        <Card key={subscription.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar>{subscription.name.charAt(0)}</Avatar>
              <Box>
                <Tooltip title="View">
                  <IconButton onClick={() => handleAddClick(subscription.type)}>
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleAddClick(subscription.type)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{subscription.name}</Typography>
            <Typography variant="body2">{subscription.description}</Typography>
            <Typography variant="body2">
              Price: {subscription.price} {subscription.type === "Merchandise" ? "KES" : ""}
            </Typography>
            {subscription.type === "Merchandise" && (
              <Typography variant="body2">Delivery: {subscription.deliveryDetails}</Typography>
            )}
            <Typography variant="body2">
              {subscription.type} Subscription
            </Typography>
            <Typography variant="body2">
              Status: {subscription.status === "inactive" ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Subscriptions</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search subscriptions"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleAddClick("treatment")} // Default to Treatment Plan initially
        >
          Add Subscription
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredSubscriptions.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No subscriptions found.
        </Typography>
      )}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ overflowY: "auto", backgroundColor: "rgba(0, 0, 0, 0.7)" }}
      >
        <Box component="form" onSubmit={handleModalSubmit} sx={{ ...modalStyle, width: 400 }}>
          <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
            Add {modalType === "treatment" ? "Treatment Plan" : modalType === "merchandise" ? "Merchandise" : "Corporate Wellness Program"}
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleFormChange}
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          />
          {modalType === "merchandise" && (
            <>
              <TextField
                margin="normal"
                fullWidth
                id="deliveryDetails"
                label="Delivery Details"
                name="deliveryDetails"
                value={formData.deliveryDetails}
                onChange={handleFormChange}
                sx={{ backgroundColor: "#fff", borderRadius: 1 }}
              />
            </>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="price"
            label="Price"
            name="price"
            value={formData.price}
            onChange={handleFormChange}
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="duration"
            label="Duration"
            name="duration"
            value={formData.duration}
            onChange={handleFormChange}
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="recurrence"
            label="Recurrence"
            name="recurrence"
            value={formData.recurrence}
            onChange={handleFormChange}
            sx={{ backgroundColor: "#fff", borderRadius: 1 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "#2C1C18", // Custom color
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserSubscriptions;
