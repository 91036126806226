import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // Adjust the path based on your folder structure

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext); // Access user state from UserContext

  if (!user) {
    // If user is not logged in, redirect to the login page or any other page
    return <Navigate to="/" />;  // Adjust the path to your login page
  }

  // If user is authenticated, render the requested page
  return children;
};

export default ProtectedRoute;
