import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faTasks, faDollarSign, faUsers, faComments, faUserMd, faRecycle, faEnvelopeOpenText, faCalendarCheck, faFileAlt, faUserCircle, faArrowLeft, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ServiceProviderAppointments from "../components/ServiceProviderAppointments";
import ServiceProviderPostRecovery from "../components/ServiceProviderPostRecovery";
import ServiceProviderQuestionnaire from "../components/ServiceProviderQuestionnaire";
import ServiceProviderSettings from "../components/ServiceProviderSettings";
import ServiceProviderSubscriptions from "../components/ServiceProviderSubscriptions";
import ServiceProviderBills from "../components/ServiceProviderBills";
import ServiceProviderClients from "../components/ServiceProviderClients";
import ServiceProviderStaff from "./ServiceProviderStaff";
import ServiceProviderTherapyAssessments from "./ServiceProviderTherapyAssessments";
import ServiceProviderTherapySessions from "./ServiceProviderTherapySessions";
import ServiceProviderTherapyTreatments from "./ServiceProviderTherapyTreatments";
import ServiceProviderCalendar from "./ServiceProviderCalendar";

import { UserContext } from "../UserContext"; // Import UserContext

const CorporateInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext); // Access user and logout
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu

  // Define icons for the main menu
  const mainMenuIcons = [
    { name: "Therapy", icon: faUserMd, subMenu: "therapy" },
    { name: "Financials", icon: faDollarSign, subMenu: "financials" },
    { name: "Administration", icon: faUserCircle, subMenu: "administration" },
    { name: "Calendar", icon: faCalendarAlt, component: "Calendar" },
    { name: "Settings", icon: faCog, component: "Settings" },
  ];

  // Define sub-menu icons for Therapy
  const therapySubMenuIcons = [
    { name: "Post Recovery", icon: faRecycle, component: "PostRecovery" },
    { name: "Questionnaire", icon: faFileAlt, component: "Questionnaire" },
  ];

  // Define sub-menu icons for Financials
  const financialSubMenuIcons = [{ name: "Billing", icon: faDollarSign, component: "Billing" }];

  // Define sub-menu icons for Administration
  const corporateSubMenuIcons = [
    { name: "Ticketing", icon: faEnvelopeOpenText, component: "Tickets" },
    { name: "Clients", icon: faUsers, component: "Clients" },
    { name: "Staff", icon: faUserCircle, component: "Teams" },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    logout(); // Call the logout function from the context
    navigate('/');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  // Handle navigation back to main menu
  const handleBackToMainMenu = () => {
    setSelectedComponent(null);
    setSubMenu(null);
    setMainMenu(true);
    setBreadcrumbTitle("Home");
  };

  // Handle click on main menu or sub-menu icons
  const handleMainMenuClick = (menu) => {
    if (menu === "Logout") {
      handleLogout();
    } else if (menu === "therapy" || menu === "financials" || menu === "administration") {
      setSubMenu(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
    } else {
      setSelectedComponent(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu); // Set the breadcrumb to component name
    }
  };

  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <ServiceProviderAppointments />;
      case 'Responses':
        return <ServiceProviderTherapyAssessments />;
      case 'Billing':
        return <ServiceProviderBills />;
      case 'Sessions':
        return <ServiceProviderTherapySessions />;
      case 'Treatments':
        return <ServiceProviderTherapyTreatments />;
      case 'PostRecovery':
        return <ServiceProviderPostRecovery />;
      case 'Questionnaire':
        return <ServiceProviderQuestionnaire />;
      case 'Calendar':
        return <ServiceProviderCalendar />;
      case 'Clients':
        return <ServiceProviderClients />;
      case 'Teams':
        return <ServiceProviderStaff />;
      case 'Settings':
        return <ServiceProviderSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{color:'#2C1C18'}}>
        <Link underline="hover" color="inherit" onClick={handleBackToMainMenu} style={{ cursor: "pointer" }}>
          Home
        </Link>
        {subMenu && !selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
        {selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
      </Breadcrumbs>
      
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "administration" && (
        <>
          {renderIcons(corporateSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CorporateInbox;
