import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "./IconMarquee";
import Navbar from "./Navbar";
import SocialMediaLinks from "./SocialMediaLinks";
import FlipAnimation from "./FlipAnimation";
import "./SliderStyles.css";
import { TreeView } from "@mui/x-tree-view";
import { TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ServicesCarousel from "./ServicesCarousel";
import ServicesMasonry from "./ServicesMasonry";
import PatientMasonry from "./PatientMasonry";
import ServicesGrid from "./ServicesGrid";

import TicketModal from "./TicketModal";

import TherapyQuestionnaire from "./TherapyQuestionnaire";
import PoweredByLogoAnimation from "./PoweredByLogoAnimation";


const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const services = [
  {
    name: "Inbox",
    description: "Your central place for notifications and messages.",
    icon: "mail",
    path: "/inbox",
  },

  {
    name: "Therapy",
    description: "Professional therapy services.",
    icon: "psychology",
    path: "/therapy",
  },
  {
    name: "Questionaire",
    description: "Engage with questionaires to make informed decisions.",
    icon: "poll",
    path: "/polls",
  },
  {
    name: "Your Data",
    description: "Checkout your historical data",
    icon: "poll",
    path: "/data",
  },

  // Add more services as needed
];

const ViewQuestionaire = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);


  const handleBillingViewClick = () => {
    navigate('/view-billing');
};

const handleDashboardViewClick = () => {
    navigate('/user-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/view-questionaire');
};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Paper
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
          <Box
            sx={{
              width: { xs: 0, sm: "200px" }, // Adjust width as needed
              overflowY: "auto",
            }}
          >
            {/* Dashboard and TreeView go here */}
            <Typography variant="h6" onClick={handleDashboardViewClick} style={{ padding: "16px" }}>
              Dashboard
            </Typography>
            {/* TreeView component */}
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem nodeId="1" label="Your Inbox">
                <TreeItem nodeId="2" label="View" />
              </TreeItem>
              <TreeItem nodeId="3" label="Therapy">
                <TreeItem nodeId="4" onClick={handleQuestionaireViewClick} label="Questionaire" />
              </TreeItem>
              <TreeItem nodeId="11" label="Billing">
                <TreeItem nodeId="12" onClick={handleBillingViewClick} label="View" />
              </TreeItem>
              <TreeItem nodeId="6" label="Exit">
                  <TreeItem nodeId="16" label={ <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}> Sign Out </Link> } />
              </TreeItem>
            </TreeView>
          </Box>
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {/* Main content goes here */}
            <TherapyQuestionnaire />
          </Box>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography color="#00e5ff" style={{ textAlign: "center" }}>
              Do you need any support?
            </Typography>
            <Button
              variant="contained"
              style={{
                margin: "auto",
                display: "block",
                backgroundColor: "#4caf50",
              }}
              onClick={handleOpen}
            >
              Talk To Us
            </Button>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <TicketModal open={openModal} onClose={handleClose} />
            </Modal>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
              <SpeedDial
                ariaLabel="SpeedDial"
                sx={{
                  "& .MuiFab-primary": {
                    backgroundColor: "#4caf50",
                    "&:hover": { backgroundColor: "#43a047" },
                  },
                }}
                icon={<SpeedDialIcon />}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                  />
                ))}
              </SpeedDial>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewQuestionaire;
