import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import Homepage from './pages/Homepage'; // Import your pages
import useAnalytics from './useAnalytics';  // Import the analytics hook
import theme from "./theme";
import Navbar from "./components/Navbar"; // Add components you need here
import Footer from './components/Footer'; // Footer for common usage
import { UserProvider } from './UserContext'; // Adjust the path accordingly
import AppRoutes from './routes';


const App = () => {
  return (
    
    <ThemeProvider theme={theme}>
        < useAnalytics />
        <UserProvider>
          <AppRoutes />
        </UserProvider>       
    </ThemeProvider>
  );
};

export default App;
