import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Chip
} from '@mui/material';
import {
  Search as SearchIcon,
  Check as ApproveIcon,
  Schedule as RescheduleIcon,
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as ViewIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { UserContext } from "../UserContext";
import ServiceProviderTherapyAssessments from './ServiceProviderTherapyAssessments';

const ServiceProviderAppointments = () => {
  const { user } = useContext(UserContext);
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openRescheduleDialog, setOpenRescheduleDialog] = useState(false);
  const [newDate, setNewDate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/sp_appointments/${user.provider_id}`);
      setAppointments(response.data);
      setFilteredAppointments(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch appointments', error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAppointments(
      appointments.filter((appointment) =>
        appointment.name.toLowerCase().includes(value) || appointment.user_email.toLowerCase().includes(value)
      )
    );
  };

  const handleApprove = async (appointment) => {
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/sp-appointment-requests/${appointment.appointment_id}`, { status: 'Confirmed' });
      setSnackbarMessage('Appointment confirmed successfully!');
      fetchAppointments();
    } catch (error) {
      setSnackbarMessage('Error confirming appointment.');
    } finally {
      setIsSubmitting(false);
      setOpenSnackbar(true);
    }
  };

  const handleSendReminder = async (appointment) => {
    setIsSubmitting(true);
    try {
      await axios.post(`https://api.cope.ke/send-reminder/${appointment.appointment_id}`);
      setSnackbarMessage('Reminder sent successfully!');
    } catch (error) {
      setSnackbarMessage('Error sending reminder.');
    } finally {
      setIsSubmitting(false);
      setOpenSnackbar(true);
    }
  };

  const handleCancelAppointment = async () => {
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/service_provider_appointments/${selectedAppointment.appointment_id}`, { status: 'Cancelled' });
      setSnackbarMessage('Appointment cancelled successfully!');
      fetchAppointments();
    } catch (error) {
      setSnackbarMessage('Error cancelling appointment.');
    } finally {
      setIsSubmitting(false);
      setOpenSnackbar(true);
      setSelectedAppointment(null);
    }
  };

  const handleOpenDetails = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleOpenRescheduleDialog = (appointment) => {
    setSelectedAppointment(appointment);
    setOpenRescheduleDialog(true);
  };

  const handleReschedule = async () => {
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/service_provider_appointments/${selectedAppointment.appointment_id}`, { appointment_date: newDate });
      setSnackbarMessage('Appointment rescheduled successfully!');
      fetchAppointments();
    } catch (error) {
      setSnackbarMessage('Error rescheduling appointment.');
    } finally {
      setIsSubmitting(false);
      setOpenRescheduleDialog(false);
      setOpenSnackbar(true);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAppointments.length > 0 ? (
            filteredAppointments.map((appointment) => (
              <TableRow key={appointment.appointment_id} onClick={() => handleOpenDetails(appointment)}>
                <TableCell>{appointment.name}</TableCell>
                <TableCell>{appointment.user_email}</TableCell>
                <TableCell>{new Date(appointment.appointment_date).toLocaleString()}</TableCell>
                <TableCell>
                  <Chip label={appointment.status} color={appointment.status === 'Pending' ? 'primary' : 'default'} />
                </TableCell>
                <TableCell>
                  <Tooltip title="View Details" arrow>
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); handleOpenDetails(appointment); }}
                      sx={{ color: '#00695C' }}
                    >
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center" sx={{ color: 'red' }}>
                No appointments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderAppointmentDetails = () => {
    const appointmentDate = new Date(selectedAppointment.appointment_date);
    const formattedDate = `${appointmentDate.toLocaleString('en-US', { weekday: 'long' })}, ${appointmentDate.toLocaleString()}`;

    return (
      <Box>
        <IconButton onClick={() => setSelectedAppointment(null)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color='#00695C' variant="h6">Appointment Details</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>{selectedAppointment.name}</strong> has scheduled an appointment with you on{' '}
          <strong>{formattedDate}.</strong>
          This appointment is currently <Chip label={selectedAppointment.status} color="primary" />.
          You may confirm, reschedule, or cancel the appointment as needed.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 2 }}>
          {selectedAppointment.status === 'Pending' && (
            <Tooltip title="Confirm Appointment">
              <IconButton onClick={() => handleApprove(selectedAppointment)} sx={{ color: '#00695C' }}>
                <ApproveIcon />
              </IconButton>
            </Tooltip>
          )}
          {selectedAppointment.status === 'In Self Assessment' &&
            new Date() - new Date(selectedAppointment.appointment_date) > 48 * 60 * 60 * 1000 && (
            <Tooltip title="Send Reminder">
              <IconButton onClick={() => handleSendReminder(selectedAppointment)} sx={{ color: '#00695C' }}>
                <RescheduleIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Reschedule Appointment">
            <IconButton onClick={() => handleOpenRescheduleDialog(selectedAppointment)} sx={{ color: '#00695C' }}>
              <RescheduleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Appointment">
            <IconButton onClick={handleCancelAppointment} sx={{ color: '#00695C' }}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>Your Appointments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search appointments"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAppointment ? (
        renderAppointmentDetails()
      ) : (
        renderTable()
      )}

      <Dialog
        open={openRescheduleDialog}
        onClose={() => setOpenRescheduleDialog(false)}
      >
        <DialogTitle>Reschedule Appointment</DialogTitle>
        <DialogContent>
          <TextField
            label="New Date and Time"
            type="datetime-local"
            fullWidth
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRescheduleDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleReschedule} color="secondary">
            {isSubmitting ? <CircularProgress size={24} /> : "Reschedule"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default ServiceProviderAppointments;
