import React, { useState, useEffect, useContext } from 'react'; 
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
  Snackbar,
  Alert,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  Download as DownloadIcon,
  Visibility as ViewIcon,
  CheckCircle as CheckIcon,
  Cancel as CancelIcon,
  Report as ReportIcon,
  ShoppingCart as CartIcon,
  Send as SendIcon
} from '@mui/icons-material';

import { UserContext } from '../UserContext';
import axios from 'axios';

const ServiceProviderTherapyAssessments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useContext(UserContext);
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [tabValue, setTabValue] = useState(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [appointmentDate, setAppointmentDate] = useState(new Date().toISOString().slice(0, 16)); // Truncate to the format required for the datetime-local input
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);


  // Helper function to format the date
const formatAppointmentDate = (date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date(date));

  const day = new Date(date).getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return formattedDate.replace(day, `${day}${daySuffix(day)}`);
};

  useEffect(() => {
    fetchAssessments();
    fetchTreatmentPlans();
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/sp-responses/${user.provider_id}`);
      setAssessments(response.data);
      setFilteredAssessments(response.data);
      setLoading(false);
    } catch (error) {
      showSnackbar('Failed to fetch assessments', 'error');
      setLoading(false);
    }
  };

  const handleOpenConfirmDelete = (assessmentId) => {
    setAssessmentToDelete(assessmentId);
    setConfirmDeleteOpen(true);
  };

  const handleSendToTherapy = async (assessment) => {
    setPlanModalOpen(true);
    setSelectedAssessment(assessment);
  };

  const handleGetReport = async (assessment) => {
    setActionLoading((prevState) => ({ ...prevState, report: true }));
    try {
      const response = await axios.post('https://api.cope.ke/openai-assistant', {
        name: assessment.name,
        responses: assessment.responses
      });
      showSnackbar('Report generated successfully!', 'success');
      assessment.analysis = response.data.analysis;
    } catch (error) {
      showSnackbar('Failed to generate report', 'error');
    } finally {
      setActionLoading((prevState) => ({ ...prevState, report: false }));
    }
  };

  const handleOpenAddEditModal = (assessment = null) => {
    setSelectedAssessment(assessment);
    // Additional modal logic for adding/editing the assessment...
  };

  const downloadCSV = (assessment) => {
    setActionLoading((prevState) => ({ ...prevState, csv: true }));
    // Logic to download the CSV
    setTimeout(() => setActionLoading((prevState) => ({ ...prevState, csv: false })), 1000);
  };

  const downloadPDF = (assessment) => {
    setActionLoading((prevState) => ({ ...prevState, pdf: true }));
    // Logic to download the PDF
    setTimeout(() => setActionLoading((prevState) => ({ ...prevState, pdf: false })), 1000);
  };

  const handleConfirmSelection = () => {
    // Open confirmation modal
    setConfirmModalOpen(true);
  };


  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAssessments(
      assessments.filter((assessment) =>
        assessment.name.toLowerCase().includes(value)
      )
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTreatmentPlans = async () => {
    try {
      const response = await axios.get('https://api.cope.ke/subscription-plan');
      setTreatmentPlans(response.data);
    } catch (error) {
      showSnackbar('Failed to fetch treatment plans', 'error');
    }
  };

  const handlePlanCartClick = (planId, price) => {
    setSelectedPlans((prevSelectedPlans) => {
      const updatedPlans = prevSelectedPlans.includes(planId)
        ? prevSelectedPlans.filter((id) => id !== planId) // Deselect plan
        : [...prevSelectedPlans, planId]; // Select plan

      setTotalAmount((prevTotal) => {
        return prevSelectedPlans.includes(planId)
          ? prevTotal - price // Subtract price when deselected
          : prevTotal + price; // Add price when selected
      });

      return updatedPlans;
    });
  };

  const handlePlanSubmit = async () => {
    setActionLoading(true);
    setPlanModalOpen(false);

    const sessionData = {
        name: selectedAssessment.name,
        user_id: selectedAssessment.id,
        provider_id: selectedAssessment.provider_id,
        user_email: selectedAssessment.user_email,
        provider_email: user.email,
        phone: user.phone,
        plans: selectedPlans,
        appointment_date: new Date(appointmentDate).toISOString(),
        price: totalAmount
    };

    try {
        const therapyResponse = await axios.post('https://api.cope.ke/therapy_sessions', sessionData);
        
        // If invoice generated, download the PDF file
        if (therapyResponse.data.invoice_file) {
            const link = document.createElement('a');
            link.href = `https://api.cope.ke/invoices/${therapyResponse.data.invoice_file}`;
            link.download = therapyResponse.data.invoice_file;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        showSnackbar('Therapy session scheduled and invoice generated!', 'success');
        setConfirmModalOpen(false);  // Close confirmation modal only on success
    } catch (error) {
        showSnackbar('Failed to schedule therapy or generate invoice', 'error');
    } finally {
        setActionLoading(false);
    }
};


  const handleCancel = () => {
    setConfirmModalOpen(false);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAssessments.length > 0 ? (
            filteredAssessments.map((assessment) => (
              <TableRow
                key={assessment.response_id}
                hover
                onClick={() => setSelectedAssessment(assessment)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{new Date(assessment.response_date).toLocaleString()}</TableCell>
                <TableCell>{assessment.name}</TableCell>
                <TableCell>
                  <Tooltip title="View Assessment">
                    <IconButton onClick={() => setSelectedAssessment(assessment)} sx={{ color: '#00695C' }}>
                      <ViewIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} sx={{ color: 'red' }} align="center">
                No assessments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = (assessment) => (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Tooltip title="Download CSV">
          <IconButton onClick={() => downloadCSV(assessment)} sx={{ color: '#00695C' }}>
            {actionLoading.csv ? <CircularProgress size={24} /> : <DownloadIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Download PDF">
          <IconButton onClick={() => downloadPDF(assessment)} sx={{ color: '#00695C' }}>
            {actionLoading.pdf ? <CircularProgress size={24} /> : <DownloadIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Assessment">
          <IconButton onClick={() => handleOpenAddEditModal(assessment)} sx={{ color: '#00695C' }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Get Report">
          <IconButton onClick={() => handleGetReport(assessment)} sx={{ color: '#00695C' }}>
            {actionLoading.report ? <CircularProgress size={24} /> : <ReportIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Send to Therapy">
          <IconButton onClick={() => handleSendToTherapy(assessment)} sx={{ color: '#00695C' }}>
            {actionLoading.therapy ? <CircularProgress size={24} /> : <SendIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Assessment">
          <IconButton onClick={() => handleOpenConfirmDelete(assessment.id)} sx={{ color: '#00695C' }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography color='#00695C' variant="h6">Assessment Details</Typography>
      <Typography variant="body1"><strong>Question:</strong> {assessment.question_text}</Typography>
      <Typography variant="body1"><strong>Selected Option:</strong> {assessment.response_text}</Typography>
    </Box>
  );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>
        Therapy Assessments
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <IconButton onClick={() => setSelectedAssessment(null)}>
            <ArrowBackIcon sx={{ color: '#00695C' }} />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Toolbar>
            <TextField
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search assessments"
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Toolbar>
        </Grid>
      </Grid>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAssessment ? (
        <Box>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Details" sx={{ color: tabValue === 0 ? '#00695C' : '#F9A64F' }} />
            <Tab label="Assessment Report" sx={{ color: tabValue === 1 ? '#00695C' : '#F9A64F' }} />
          </Tabs>
          {tabValue === 0 && renderDetails(selectedAssessment)}
          {tabValue === 1 && (
            <Box>
              <Typography variant="body1">
                {selectedAssessment.analysis
                  ? selectedAssessment.analysis
                  : 'No report generated. Click "Get Report" to request one.'}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        renderTable()
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Treatment Plan Modal */}
      <Dialog open={planModalOpen} onClose={() => setPlanModalOpen(false)}>
        <DialogTitle sx={{color:'#00695C'}} >Select Treatment Plan</DialogTitle>
        <DialogContent>
          {/* Native HTML date-time picker */}
          <Tooltip title="Select Appointment Date & Time">
            <TextField
            label="Appointment Date & Time"
            type="datetime-local"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
              color:'#2C1C18'
            }}
            fullWidth
          />
          </Tooltip>
          <Box mt={2}>
            <Typography sx={{color:'#00695C'}} variant="h6">Total Amount: Ksh {totalAmount.toFixed(2)}</Typography> {/* Ensure two decimal points */}
          </Box>
          {treatmentPlans.map((plan) => (
            <Box
            key={plan.plan_id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
              cursor: 'pointer',
              '&:hover': { backgroundColor: '#f0f0f0' }
            }}
          >
            <Checkbox
              sx={{color:'#2C1C18'}}
              checked={selectedPlans.includes(plan.plan_id)}
              onChange={() => handlePlanCartClick(plan.plan_id, Number(plan.price))}
            />
            <Typography variant="body1">
              {plan.plan_type} - Ksh {Number(plan.price).toFixed(2)}
            </Typography>
            <Tooltip title="Add to Cart">
              <IconButton 
                onClick={() => handlePlanCartClick(plan.plan_id, Number(plan.price))} 
                sx={{ color: '#00695C', '&:hover': { transform: 'scale(1.1)' } }}
              >
                <CartIcon />
              </IconButton>
            </Tooltip>
          </Box>    
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirmSelection} disabled={!selectedPlans.length}>
            Confirm Selection
          </Button>
        </DialogActions>
      </Dialog>
      {/* Confirmation Modal */}
      <Dialog open={confirmModalOpen} onClose={handleCancel}>
        <DialogTitle sx={{color: '#00695C'}}>Confirm Appointment</DialogTitle>
        <DialogContent>
          <Typography>
            You have set the appointment for {formatAppointmentDate(appointmentDate)} for the selected plans. The client will be billed Ksh {totalAmount.toFixed(2)}.
          </Typography>
        </DialogContent>
        <DialogActions>
        <IconButton onClick={handleCancel} sx={{ color: 'red' }}>
          <CancelIcon />
        </IconButton>
        <IconButton onClick={handlePlanSubmit} sx={{ color: 'green' }}>
          {actionLoading ? <CircularProgress size={24} /> : <CheckIcon />}
        </IconButton>
      </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ServiceProviderTherapyAssessments;
