import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Tooltip
} from '@mui/material';
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  PersonAdd as AddPhotoIcon
} from '@mui/icons-material';
import axios from 'axios';
import { UserContext } from '../UserContext';


const ServiceProviderStaff = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useContext(UserContext);
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    designation: '',
    provider_id: ''
  });

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const response = await axios.get('https://api.cope.ke/sp-staff');
      setStaff(response.data);
      setFilteredStaff(response.data);
      setLoading(false);
    } catch (error) {
      showSnackbar('Failed to fetch staff', 'error');
      setLoading(false);
    }
  };

  const handleAddEditClick = (staffMember = null) => {
    setSelectedStaff(staffMember);
    setFormData(staffMember || { name: '', email: '', phone: '', designation: '', provider_id: '' });
    setOpenAddEditDialog(true);
  };

  const handleCloseAddEditDialog = () => setOpenAddEditDialog(false);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredStaff(
      staff.filter(
        (staffMember) =>
          staffMember.name.toLowerCase().includes(value) ||
          staffMember.email.toLowerCase().includes(value) ||
          staffMember.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (staffMember) => {
    setSelectedStaff(staffMember);
    setFormData(staffMember);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedStaff(null);
    setFormData({
      date: '',
      name: '',
      email: '',
      phone: '',
      status: ''
    });
  };

  const handleOpenAddEditModal = (staffMember = null) => {
    setSelectedStaff(staffMember);
    setFormData(staffMember || {
      date: '',
      name: '',
      email: '',
      phone: '',
      status: ''
    });
    setOpenAddEditModal(true);
  };

  const handlePhoneChange = (event) => {
    let phone = event.target.value;
    if (phone.startsWith('07')) phone = '+254' + phone.slice(1);
    setFormData({ ...formData, phone });
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedStaff(null);
    setFormData({
      date: '',
      name: '',
      email: '',
      phone: '',
      status: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      if (selectedStaff) {
        await axios.put(`https://api.cope.ke/sp-staff/${selectedStaff.id}`, formData);
      } else {
        await axios.post('https://api.cope.ke/sp-staff', { ...formData, provider_id: user?.provider_id });
      }
      setSaving(false);
      setOpenAddEditDialog(false);
      fetchStaff();
      handleCloseAddEditModal();
      showSnackbar('Staff saved successfully', 'success');
    } catch (error) {
      setSaving(false);
      showSnackbar('Failed to save staff', 'error');
      
    }
  };

  const handleDelete = async (staffId) => {
    try {
      await axios.delete(`https://api.cope.ke/sp-staff/${staffId}`);
      fetchStaff();
    } catch (error) {
      showSnackbar('Failed to delete staff', 'error');
    }
  };

  const showSnackbar = (message, severity) => setSnackbar({ open: true, message, severity });


  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredStaff.length > 0 ? (
            filteredStaff.map((staffMember) => (
              <TableRow key={staffMember.id} onClick={() => handleOpenDetailsModal(staffMember)}>
                <TableCell>{staffMember.name}</TableCell>
                <TableCell>{staffMember.email}</TableCell>
                <TableCell>{staffMember.phone}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(staffMember); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(staffMember.id); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6}  sx={{color:'red'}}  align="center">
                No staff records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Staff Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="date"
          label="Date"
          name="date"
          type="datetime-local"
          value={formData.date}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="status"
          label="Status"
          name="status"
          value={formData.status}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="recoveryDetails"
          label="Recovery Details"
          name="recoveryDetails"
          value={formData.recoveryDetails}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>
        Your Staff
      </Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search staff records"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Tooltip title="Add Staff">
          <IconButton color="primary" onClick={() => handleAddEditClick()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable()
      )}

      {/* Add/Edit Staff Modal */}
      <Dialog open={openAddEditDialog} onClose={handleCloseAddEditDialog}>
        <DialogTitle>{selectedStaff ? 'Edit Staff' : 'Add Staff'}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            placeholder='Name'
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            InputLabelProps={{color:'red'}}
          />
          <TextField
            sx={{ mb: 2 }}
            placeholder='Email'
            fullWidth
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            sx={{ mb: 2 }}
            placeholder="Phone"
            fullWidth
            value={formData.phone}
            onChange={handlePhoneChange}
          />
          <TextField
            sx={{ mb: 2 }}
            placeholder  ="Designation"
            fullWidth
            value={formData.designation}
            onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
          />
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Tooltip title="Cancel">
            <IconButton onClick={handleCloseAddEditDialog} color="secondary">
              <CancelIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save">
            <IconButton onClick={handleSave} color="primary" disabled={saving}>
              {saving ? <CircularProgress size={24} /> : <SaveIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Dialog>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
  width: 350
};

export default ServiceProviderStaff;
