import React, { useState, useContext } from 'react';
import {
  List, ListItem, ListItemText, ListItemIcon, Divider, Collapse, Badge
} from '@mui/material';
import {
  Inbox as InboxIcon,
  Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
  ExpandLess,
  ExpandMore,
  Event as EventIcon,
  Assignment as AssignmentIcon,
  LocalHospital as LocalHospitalIcon,
  Healing as HealingIcon,
  Group as GroupIcon,
  Receipt as ReceiptIcon,
  Support as SupportIcon,
  People as PeopleIcon,
  CalendarToday as CalendarTodayIcon,
  Settings as SettingsIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { UserContext } from '../UserContext';

const CorporateMenu = ({
  handleProfileViewClick,
  handleSubscriptionsViewClick,
  handlePostRecoveryViewClick,
  handleBillingViewClick,
  handleDashboardViewClick,
  handleQuestionaireViewClick,
  handleStaffViewClick,
  
}) => {
  const { logout } = useContext(UserContext);

  // States for dropdown open/close
  const [openTherapy, setOpenTherapy] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openFinancials, setOpenFinancials] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <List component="nav" aria-label="corporate user menu">
      {/* Dashboard */}
      <ListItem button onClick={handleDashboardViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Your Dashboard" />
      </ListItem>

      {/* Therapy Dropdown */}
      <ListItem button onClick={() => setOpenTherapy(!openTherapy)} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Therapy" />
        {openTherapy ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openTherapy} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleQuestionaireViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Questionnaire" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handlePostRecoveryViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <HealingIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Post Recovery" />
          </ListItem>
        </List>
      </Collapse>

      {/* Administration Dropdown */}
      <ListItem button onClick={() => setOpenAdmin(!openAdmin)} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Administration" />
        {openAdmin ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleStaffViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Staff" />
          </ListItem>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleSubscriptionsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Ticketing" />
          </ListItem>
        </List>
      </Collapse>

      {/* Financials Dropdown */}
      <ListItem button onClick={() => setOpenFinancials(!openFinancials)} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Financials" />
        {openFinancials ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openFinancials} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleBillingViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Billing" />
          </ListItem>
        </List>
      </Collapse>

      {/* Calendar */}
      <ListItem button sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <Badge color="secondary">
            <CalendarTodayIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Calendar" />
      </ListItem>

      {/* Settings Dropdown */}
      <ListItem button onClick={() => setOpenSettings(!openSettings)} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Settings" />
        {openSettings ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openSettings} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4, cursor: 'pointer' }} onClick={handleProfileViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Profile Settings" />
          </ListItem>
        </List>
      </Collapse>

      <Divider />

      {/* Logout */}
      <ListItem button onClick={logout} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Sign Out" />
      </ListItem>
    </List>
  );
};

export default CorporateMenu;
